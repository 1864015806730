import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type CreateOneEntrySocietyListMutationVariables = {
  input: Types.CreateEntrySocietyListInput;
};


export type CreateOneEntrySocietyListMutation = { createOneEntrySocietyList: { entrySocietyList: Pick<Types.EntrySocietyList, 'id' | 'title'> } };


export const CreateOneEntrySocietyListDocument = gql`
    mutation createOneEntrySocietyList($input: CreateEntrySocietyListInput!) {
  createOneEntrySocietyList(input: $input) {
    entrySocietyList {
      id
      title
    }
  }
}
    `;
export type CreateOneEntrySocietyListMutationFn = ApolloReactCommon.MutationFunction<CreateOneEntrySocietyListMutation, CreateOneEntrySocietyListMutationVariables>;
export function useCreateOneEntrySocietyListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneEntrySocietyListMutation, CreateOneEntrySocietyListMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneEntrySocietyListMutation, CreateOneEntrySocietyListMutationVariables>(CreateOneEntrySocietyListDocument, baseOptions);
      }
export type CreateOneEntrySocietyListMutationHookResult = ReturnType<typeof useCreateOneEntrySocietyListMutation>;
export type CreateOneEntrySocietyListMutationResult = ApolloReactCommon.MutationResult<CreateOneEntrySocietyListMutation>;
export type CreateOneEntrySocietyListMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneEntrySocietyListMutation, CreateOneEntrySocietyListMutationVariables>;