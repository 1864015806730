import React from 'react';

type Props = {
  extraClassNames?: string;
  disabled?: boolean;
};

function TableContentCell({ children, disabled, extraClassNames }: React.PropsWithChildren<Props>) {
  const classNames = extraClassNames ? ` ${extraClassNames}` : '';
  const disabledStyle = disabled ? ` text-gray-500` : '';

  return <td className={`px-4 py-4 border-b border-gray-200${classNames}${disabledStyle}`}>{children}</td>;
}

export default TableContentCell;
