import React from 'react';

type Props = {
  amount: number;
};

function CountLabel({ amount }: Props) {
  return amount > 0 ? (
    <span className={`text-label text-xs bg-gray-200 text-blue-700 py-1`}>{amount}</span>
  ) : (
    <span className={`text-label text-xs bg-gray-200 text-gray-700 py-1`}>{amount}</span>
  );
}

export default CountLabel;
