import React from 'react';
import Select, { Styles, ValueType, OptionTypeBase } from 'react-select';

import { colors } from '../../../utils/theme';

interface OptionType extends OptionTypeBase {
  value?: string;
  label?: string;
}

type Props = {
  name: string;
  label: string;
  error?: string | null;
  onChange: (value: string) => void;
} & Omit<React.ComponentProps<typeof Select>, 'onChange'>;

function SelectInput(props: Props) {
  const { label, name, error, onChange, ...selectProps } = props;

  const customStyles: Partial<Styles> = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: isDisabled ? colors.gray[200] : 'transparent',
      borderColor: colors.gray[300],
    }),
  };

  return (
    <div className="flex flex-1 flex-col">
      <label className="font-medium text-sm text-gray-600 mb-2" htmlFor={name}>
        {label}
      </label>
      <div className="flex flex-1 flex-col">
        <Select
          {...selectProps}
          styles={customStyles}
          className="w-full rounded text-gray-700 leading-tight"
          onChange={(option: ValueType<OptionType>) => {
            const value = (option as OptionType).value;

            if (!!value) {
              onChange(value);
            }
          }}
        />
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>
    </div>
  );
}

export default SelectInput;
