import React from 'react';
import { IconType } from 'react-icons/lib/cjs';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = {
  to: string;
  title: string;
  Icon: IconType;
} & Pick<NavLinkProps, 'exact'>;

function MenuNavLink(props: Props) {
  return (
    <NavLink
      to={props.to}
      activeClassName="bg-gray-900"
      activeStyle={{ color: 'white' }}
      className={
        'flex flex-row items-center px-3 py-2 rounded-md tracking-wide text-gray-500 hover:bg-gray-900 hover:text-white'
      }
      exact={props.exact}
    >
      <props.Icon />
      <span className="ml-2 text-sm">{props.title}</span>
    </NavLink>
  );
}

export default MenuNavLink;
