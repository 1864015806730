import * as Types from '../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetSettingsQueryVariables = {};


export type GetSettingsQuery = { getSettings?: Types.Maybe<SystemSettingsFragment> };

export type UpdateSettingsMutationVariables = {
  data: Types.SettingUpdateInput;
};


export type UpdateSettingsMutation = { updateSettings?: Types.Maybe<SystemSettingsFragment> };

export type SystemSettingsFragment = Pick<Types.Setting, 'id' | 'outgoing_email_address' | 'incoming_email_address' | 'max_failed_attempts' | 'session_timeout' | 'google_analytics_tracking_id'>;

export const SystemSettingsFragmentDoc = gql`
    fragment SystemSettings on Setting {
  id
  outgoing_email_address
  incoming_email_address
  max_failed_attempts
  session_timeout
  google_analytics_tracking_id
}
    `;
export const GetSettingsDocument = gql`
    query getSettings {
  getSettings(where: {id: 1}) {
    ...SystemSettings
  }
}
    ${SystemSettingsFragmentDoc}`;
export function useGetSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, baseOptions);
      }
export function useGetSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, baseOptions);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = ApolloReactCommon.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;
export const UpdateSettingsDocument = gql`
    mutation updateSettings($data: SettingUpdateInput!) {
  updateSettings(data: $data, where: {id: 1}) {
    ...SystemSettings
  }
}
    ${SystemSettingsFragmentDoc}`;
export type UpdateSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export function useUpdateSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, baseOptions);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;