import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
};

function SEO({ title }: Props) {
  return (
    <Helmet titleTemplate={`%s - ${process.env.REACT_APP_TITLE}`}>
      <title>{title}</title>
    </Helmet>
  );
}

export default SEO;
