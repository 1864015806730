import gql from 'graphql-tag';
import React from 'react';
import { useHistory, useLocation, NavLink } from 'react-router-dom';

import AlertDanger from '../../components/system/alerts/AlertDanger';
import AlertWarning from '../../components/system/alerts/AlertWarning';
import CompanyCard from '../../components/system/cards/CompanyCard';
import SEO from '../../components/system/seo';
import { MIN_SIGN_IN_ATTEMPTS_LEFT_BEFORE_WARNING, SUSPENDED_ACCOUNT_ERROR } from '../../constants/auth';
import AuthService from '../../services/AuthService';
import { getMutationErrorAttribute } from '../../utils/graphql';
import { useSignInMutation } from './index.operations';
import SignInForm, { SignInFormValues } from './SignInForm';

function SignInScreen() {
  const history = useHistory();
  const location = useLocation<{ from: string }>();

  const [signIn, { loading, error }] = useSignInMutation({
    onCompleted: (data) => {
      const token = data?.signIn.token;

      if (!!token) {
        AuthService.setToken(token);
        history.replace(from);
      }
    },
  });

  async function handleSubmit(values: SignInFormValues) {
    await signIn({ variables: { input: values } });
  }

  const { from } = location.state || { from: { pathname: '/' } };
  const signInAttemptsLeft = getMutationErrorAttribute(error, 'signInAttemptsLeft') || 0;
  const isAccountSuspended = getMutationErrorAttribute(error, 'code') === SUSPENDED_ACCOUNT_ERROR;
  const showSignInAttemptsWarning =
    signInAttemptsLeft > 0 && signInAttemptsLeft <= MIN_SIGN_IN_ATTEMPTS_LEFT_BEFORE_WARNING;

  return (
    <>
      <SEO title="Sign In" />

      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-200">
        <div className="max-w-md w-full">
          <CompanyCard>
            <div className="my-12">
              <h1 className="text-3xl font-semibold text-gray-800">Welcome</h1>
              <p className="text-gray-600">Sign in to use your account</p>
            </div>

            {isAccountSuspended && (
              <AlertDanger
                description="Your account has been suspended. Please, contact your system administrator."
                containerClassName="mb-5"
              />
            )}

            {showSignInAttemptsWarning && (
              <AlertWarning
                description={`You have ${signInAttemptsLeft} more attempt(s) left.`}
                containerClassName="mb-5"
              />
            )}

            <SignInForm loading={loading} error={error} onSubmit={handleSubmit} />
          </CompanyCard>

          <NavLink
            to="request-password-reset"
            className="block text-center m-6 transition duration-200 ease-in-out text-gray-600 hover:text-gray-800"
          >
            Forgot Password
          </NavLink>
        </div>
      </div>
    </>
  );
}

gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      token
    }
  }
`;

export default SignInScreen;
