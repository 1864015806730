import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntryListQueryVariables = {
  where: Types.EntryListWhereUniqueInput;
};


export type EntryListQuery = { entryList?: Types.Maybe<ReturnEntryListFragment> };

export type UpdateOneEntryListMutationVariables = {
  data: Types.EntryListUpdateInput;
  where: Types.EntryListWhereUniqueInput;
};


export type UpdateOneEntryListMutation = { updateOneEntryList?: Types.Maybe<ReturnEntryListFragment> };

export type ReturnEntryListFragment = Pick<Types.EntryList, 'id' | 'title'>;

export const ReturnEntryListFragmentDoc = gql`
    fragment ReturnEntryList on EntryList {
  id
  title
}
    `;
export const EntryListDocument = gql`
    query entryList($where: EntryListWhereUniqueInput!) {
  entryList(where: $where) {
    ...ReturnEntryList
  }
}
    ${ReturnEntryListFragmentDoc}`;
export function useEntryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryListQuery, EntryListQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryListQuery, EntryListQueryVariables>(EntryListDocument, baseOptions);
      }
export function useEntryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryListQuery, EntryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryListQuery, EntryListQueryVariables>(EntryListDocument, baseOptions);
        }
export type EntryListQueryHookResult = ReturnType<typeof useEntryListQuery>;
export type EntryListLazyQueryHookResult = ReturnType<typeof useEntryListLazyQuery>;
export type EntryListQueryResult = ApolloReactCommon.QueryResult<EntryListQuery, EntryListQueryVariables>;
export const UpdateOneEntryListDocument = gql`
    mutation updateOneEntryList($data: EntryListUpdateInput!, $where: EntryListWhereUniqueInput!) {
  updateOneEntryList(data: $data, where: $where) {
    ...ReturnEntryList
  }
}
    ${ReturnEntryListFragmentDoc}`;
export type UpdateOneEntryListMutationFn = ApolloReactCommon.MutationFunction<UpdateOneEntryListMutation, UpdateOneEntryListMutationVariables>;
export function useUpdateOneEntryListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneEntryListMutation, UpdateOneEntryListMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneEntryListMutation, UpdateOneEntryListMutationVariables>(UpdateOneEntryListDocument, baseOptions);
      }
export type UpdateOneEntryListMutationHookResult = ReturnType<typeof useUpdateOneEntryListMutation>;
export type UpdateOneEntryListMutationResult = ApolloReactCommon.MutationResult<UpdateOneEntryListMutation>;
export type UpdateOneEntryListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneEntryListMutation, UpdateOneEntryListMutationVariables>;