import React from 'react';

import Breadcrumb, { BreadcrumbItemType } from './Breadcrumb';

export type BreadcrumbsType = BreadcrumbItemType[];
type Props = {
  title: string;
  breadcrumbs?: BreadcrumbsType;
};

function PageHeader(props: React.PropsWithChildren<Props>) {
  return (
    <div className="flex items-center justify-between mb-8">
      <div>
        <h1 className="text-3xl font-semibold text-gray-800">{props.title}</h1>
        <Breadcrumb items={props.breadcrumbs} />
      </div>

      {!!props.children && <div className="flex flex-row items-center print:hidden">{props.children}</div>}
    </div>
  );
}

export default PageHeader;
