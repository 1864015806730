import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient, ApolloError } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';

import { UNAUTHENTICATED_ERROR } from '../constants/auth';
import { API_URL } from '../constants/url';
import AuthService from '../services/AuthService';
import { getMutationErrorAttribute } from '../utils/graphql';

const isOnProduction = process.env.REACT_APP_STAGE === 'production';
const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const errorCode = getMutationErrorAttribute({ graphQLErrors } as ApolloError, 'code');

    if (errorCode === UNAUTHENTICATED_ERROR) {
      AuthService.removeToken();
      cache.reset();

      if (document) {
        document.location.reload(true);
      }
    }

    if (!isOnProduction) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }
  }

  if (networkError && !isOnProduction) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();

  if (!!token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  return {};
});

const httpLink = new HttpLink({ uri: API_URL });
const httpLinkOptions = isOnProduction
  ? [errorLink, authLink.concat(httpLink)]
  : [apolloLogger, errorLink, authLink.concat(httpLink)];

const apolloClient = new ApolloClient({
  link: ApolloLink.from(httpLinkOptions),
  cache: cache,
});

export default apolloClient;
