import React from 'react'

type Props = {}

function DroppableContainer(props: React.PropsWithChildren<Props>) {
  return (
    <div className="min-w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200 bg-white">
      {props.children}
    </div>
  )
}

export default DroppableContainer;
