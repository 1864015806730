import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

function TablePanelContainer({ children, ...props }: React.PropsWithChildren<Props>) {
  return (
    <div
      className="block align-middle min-w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200 bg-white"
      {...props}
    >
      {children}
    </div>
  );
}

export default TablePanelContainer;
