import React from 'react';

type Props = {
  onClick: () => void;
};

function FilterButton(props: Props) {
  return (
    <button
      className="bg-gray-600 text-white text-sm font-medium shadow rounded-md px-4 py-2 focus:outline-none focus:shadow-outline hover:bg-gray-700 w-full md:w-auto"
      onClick={props.onClick}
    >
      Filter
    </button>
  );
}

export default FilterButton;
