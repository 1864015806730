import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import EntryForm from '../../../components/system/forms/EntryForm';
import { useEntrySocietyListsOnCreateQuery, useCreateOneEntrySocietyMutation } from './AddEntryForm.operations';

type Props = {
  afterSubmit: () => void;
};

function AddEntryForm(props: Props) {
  const { data, loading } = useEntrySocietyListsOnCreateQuery();
  const [createEntry] = useCreateOneEntrySocietyMutation({ refetchQueries: ['entrySocieties'], awaitRefetchQueries: true });
  
  const initialValues = { title: '', list_id: '' };
  const lists = data?.entrySocietyLists || [];

  return (
    <>
      <EntryForm
        initialValues={initialValues}
        lists={lists}
        isLoadingLists={loading}
        onSubmit={async (values, actions) => {
          try {
            await createEntry({ variables: { input: { title: values.title, list_id: String(values.list_id) } } });
            toast.success(`${values.title} has been created!`);

            props.afterSubmit();
          } catch {
            toast.error('Oops, something went wrong.');
          }
        }}
      />
    </>
  );
}

gql`
  query entrySocietyListsOnCreate {
    entrySocietyLists {
      id
      title
    }
  }

  mutation createOneEntrySociety($input: CreateEntrySocietyInput!) {
    createOneEntrySociety(input: $input) {
      entrySociety {
        id
        title
      }
    }
  }
`;

export default AddEntryForm;
