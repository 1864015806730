import { useFormik, FormikConfig } from 'formik';

function useForm<T>({
  initialValues,
  validateOnChange,
  validateOnBlur,
  validateOnMount,
  isInitialValid,
  enableReinitialize,
  onSubmit,
  ...rest
}: FormikConfig<T>) {
  const formik = useFormik<T>({
    initialValues,
    validateOnChange,
    validateOnBlur,
    validateOnMount,
    isInitialValid,
    enableReinitialize,
    onSubmit,
    ...rest,
  });

  return formik;
}

export default useForm;
