import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import EntryForm, { EntryFormValues } from '../../../components/system/forms/EntryForm';
import { useEntrySocietyListsOnUpdateQuery, useEntrySocietyQuery, useUpdateOneEntrySocietyMutation } from './EditEntryForm.operations';

type Props = {
  entryId: string;
};

function EditEntryForm(props: Props) {
  const { data: listData, loading: loadingList } = useEntrySocietyListsOnUpdateQuery();
  const { data, loading, error } = useEntrySocietyQuery({
    variables: { where: { id: parseInt(props.entryId) } },
  });

  const [editEntry] = useUpdateOneEntrySocietyMutation({ refetchQueries: ['entries'], awaitRefetchQueries: true });

  const lists = listData?.entrySocietyLists || [];
  const initialValues = { 
    title: data?.entrySociety?.title || '',
    list_id: data?.entrySociety?.list.id || ''
  };

  async function handleSubmit(values: EntryFormValues) {
    try {
      await editEntry({
        variables: {
          data: { title: values.title, list: { connect: { id: Number(values.list_id) } } },
          where: { id: Number(props.entryId) }
        },
      });

      toast.success(`${values.title} has been updated!`);
    } catch {
      toast.error('Oops, something went wrong.');
    }
  }

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the list. Please, try to refresh this page."
          containerClassName="mb-8"
        />
      )}

      <EntryForm
        initialValues={initialValues}
        initialList={data?.entrySociety?.list}
        lists={lists}
        isLoadingLists={loadingList}
        isDisabled={loading || !!error}
        onSubmit={handleSubmit}
      />
    </>
  );
}

gql`
  query entrySocietyListsOnUpdate {
    entrySocietyLists {
      id
      title
    }
  }

  query entrySociety($where: EntrySocietyWhereUniqueInput!) {
    entrySociety(where: $where) {
      ...ReturnEntrySociety
    }
  }

  mutation updateOneEntrySociety($data: EntrySocietyUpdateInput!, $where: EntrySocietyWhereUniqueInput!) {
    updateOneEntrySociety(data: $data, where: $where) {
      ...ReturnEntrySociety
    }
  }

  fragment ReturnEntrySociety on EntrySociety {
    id
    title
    list {
      id
      title
    }
  }
`;

export default EditEntryForm;
