import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntrySocietyListsOnUpdateQueryVariables = {};


export type EntrySocietyListsOnUpdateQuery = { entrySocietyLists: Array<Pick<Types.EntrySocietyList, 'id' | 'title'>> };

export type EntrySocietyQueryVariables = {
  where: Types.EntrySocietyWhereUniqueInput;
};


export type EntrySocietyQuery = { entrySociety?: Types.Maybe<ReturnEntrySocietyFragment> };

export type UpdateOneEntrySocietyMutationVariables = {
  data: Types.EntrySocietyUpdateInput;
  where: Types.EntrySocietyWhereUniqueInput;
};


export type UpdateOneEntrySocietyMutation = { updateOneEntrySociety?: Types.Maybe<ReturnEntrySocietyFragment> };

export type ReturnEntrySocietyFragment = (
  Pick<Types.EntrySociety, 'id' | 'title'>
  & { list: Pick<Types.EntrySocietyList, 'id' | 'title'> }
);

export const ReturnEntrySocietyFragmentDoc = gql`
    fragment ReturnEntrySociety on EntrySociety {
  id
  title
  list {
    id
    title
  }
}
    `;
export const EntrySocietyListsOnUpdateDocument = gql`
    query entrySocietyListsOnUpdate {
  entrySocietyLists {
    id
    title
  }
}
    `;
export function useEntrySocietyListsOnUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyListsOnUpdateQuery, EntrySocietyListsOnUpdateQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyListsOnUpdateQuery, EntrySocietyListsOnUpdateQueryVariables>(EntrySocietyListsOnUpdateDocument, baseOptions);
      }
export function useEntrySocietyListsOnUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyListsOnUpdateQuery, EntrySocietyListsOnUpdateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyListsOnUpdateQuery, EntrySocietyListsOnUpdateQueryVariables>(EntrySocietyListsOnUpdateDocument, baseOptions);
        }
export type EntrySocietyListsOnUpdateQueryHookResult = ReturnType<typeof useEntrySocietyListsOnUpdateQuery>;
export type EntrySocietyListsOnUpdateLazyQueryHookResult = ReturnType<typeof useEntrySocietyListsOnUpdateLazyQuery>;
export type EntrySocietyListsOnUpdateQueryResult = ApolloReactCommon.QueryResult<EntrySocietyListsOnUpdateQuery, EntrySocietyListsOnUpdateQueryVariables>;
export const EntrySocietyDocument = gql`
    query entrySociety($where: EntrySocietyWhereUniqueInput!) {
  entrySociety(where: $where) {
    ...ReturnEntrySociety
  }
}
    ${ReturnEntrySocietyFragmentDoc}`;
export function useEntrySocietyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyQuery, EntrySocietyQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyQuery, EntrySocietyQueryVariables>(EntrySocietyDocument, baseOptions);
      }
export function useEntrySocietyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyQuery, EntrySocietyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyQuery, EntrySocietyQueryVariables>(EntrySocietyDocument, baseOptions);
        }
export type EntrySocietyQueryHookResult = ReturnType<typeof useEntrySocietyQuery>;
export type EntrySocietyLazyQueryHookResult = ReturnType<typeof useEntrySocietyLazyQuery>;
export type EntrySocietyQueryResult = ApolloReactCommon.QueryResult<EntrySocietyQuery, EntrySocietyQueryVariables>;
export const UpdateOneEntrySocietyDocument = gql`
    mutation updateOneEntrySociety($data: EntrySocietyUpdateInput!, $where: EntrySocietyWhereUniqueInput!) {
  updateOneEntrySociety(data: $data, where: $where) {
    ...ReturnEntrySociety
  }
}
    ${ReturnEntrySocietyFragmentDoc}`;
export type UpdateOneEntrySocietyMutationFn = ApolloReactCommon.MutationFunction<UpdateOneEntrySocietyMutation, UpdateOneEntrySocietyMutationVariables>;
export function useUpdateOneEntrySocietyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneEntrySocietyMutation, UpdateOneEntrySocietyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneEntrySocietyMutation, UpdateOneEntrySocietyMutationVariables>(UpdateOneEntrySocietyDocument, baseOptions);
      }
export type UpdateOneEntrySocietyMutationHookResult = ReturnType<typeof useUpdateOneEntrySocietyMutation>;
export type UpdateOneEntrySocietyMutationResult = ApolloReactCommon.MutationResult<UpdateOneEntrySocietyMutation>;
export type UpdateOneEntrySocietyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneEntrySocietyMutation, UpdateOneEntrySocietyMutationVariables>;