import exportFromJSON from 'export-from-json';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import ExportDropdownButton from '../../../components/system/buttons/custom/ExportDropdownButton';
import PrintButton from '../../../components/system/buttons/custom/PrintButton';
import FilterButton from '../../../components/system/buttons/FilterButton';
import DraggableItem from '../../../components/system/elements/DraggableItem';
import DroppableArea from '../../../components/system/elements/DroppableArea';
import DroppableContainer from '../../../components/system/elements/DroppableContainer';
import SearchFilter from '../../../components/system/filters/custom/SearchFilter';
import DestructiveAlertModal from '../../../components/system/modals/DestructiveAlertModal';
import EmptyPanel from '../../../components/system/panel/EmptyPanel';
import TableFiltersActionsContainer from '../../../components/system/table/TableFiltersActionsContainer';
import TableFiltersContainer from '../../../components/system/table/TableFiltersContainer';
import TableFiltersForm from '../../../components/system/table/TableFiltersForm';
import { ColumnOrderedByType } from '../../../components/system/table/TableHeader';
import { EntrySocietyListWhereInput, OrderByArg } from '../../../types/graphql';
import getOrderDiff from '../../../utils/helpers/getOrderDiff';
import {
  EntrySocietyListsQuery,
  useDeleteOneEntrySocietyListMutation,
  useEntrySocietyListsQuery,
  useUpdateEntrySocietyListOrderMutation,
} from './EntryListsTable.operations';

type EntrySocietyLists = EntrySocietyListsQuery['entrySocietyLists'];

const DEFAULT_ORDER: ColumnOrderedByType = { order_by: OrderByArg['Asc'] };

function EntrySocietyListsTable() {
  const { data, loading, error, refetch } = useEntrySocietyListsQuery({ variables: { orderBy: DEFAULT_ORDER } });

  const [updateEntriesOrder] = useUpdateEntrySocietyListOrderMutation();
  const [deleteEntryList, { loading: removing }] = useDeleteOneEntrySocietyListMutation({
    refetchQueries: ['entrySocietyLists'],
  });

  const [deletableItemId, setDeletableItemId] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<EntrySocietyLists>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const entrySocietyLists = data?.entrySocietyLists;
  const hasNoResults = !loading && !!items && items.length <= 0;

  useEffect(() => {
    if (entrySocietyLists) {
      setItems(entrySocietyLists);
    }
  }, [entrySocietyLists]);

  async function buildExportablePayload() {
    const data: {}[] = [];
    const fileName = 'entry-lists';

    items.forEach((entryList) => {
      data.push({
        ID: entryList.id,
        Title: entryList.title,
      });
    });

    return { data, fileName };
  }

  function handleFilterChange(filterId: string, value: any) {
    setSearchTerm(value);
  }

  function handleFilter(event?: React.FormEvent) {
    event?.preventDefault();

    if (!searchTerm) {
      return refetch({ where: {} }).catch(() => {});
    }

    const searchTermAsInt = parseInt(searchTerm, 10);
    const conditions: EntrySocietyListWhereInput[] = [{ title: { contains: searchTerm } }];

    if (searchTermAsInt) {
      conditions.push({ id: { equals: searchTermAsInt } });
    }

    refetch({ where: { OR: conditions } });
  }

  function handleOrderChanged(updatedItems: EntrySocietyLists) {
    setItems(updatedItems);

    const entries = getOrderDiff(items, updatedItems);
    updateEntriesOrder({ variables: { input: { data: entries } } });
  }

  function handleEdit(entryListId: string) {
    history.push(`/lists-society/${entryListId}`);
  }

  async function handleDeleteEntryList() {
    try {
      await deleteEntryList({ variables: { where: { id: Number(deletableItemId) } } });
    } finally {
      setDeletableItemId(undefined);
    }
  }

  async function handleExportAsCSV() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'csv' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  async function handleExportAsXLS() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'xls' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the lists. Please, try to refresh this page."
          containerClassName="shadow"
        />
      )}

      <DestructiveAlertModal
        isOpen={!!deletableItemId}
        title="Delete List"
        message={`Are you sure you want to delete this list?`}
        destructiveLabel="Delete"
        disabled={loading || removing}
        onDestructiveClick={handleDeleteEntryList}
        onCancelClick={() => setDeletableItemId(undefined)}
      />

      <TableFiltersContainer>
        <TableFiltersForm onSubmit={handleFilter}>
          <SearchFilter width="64" onChange={handleFilterChange} />

          <FilterButton onClick={handleFilter} />
        </TableFiltersForm>

        <TableFiltersActionsContainer loading={loading}>
          <PrintButton />
          <ExportDropdownButton onClickDownloadCSV={handleExportAsCSV} onClickDownloadExcel={handleExportAsXLS} />
        </TableFiltersActionsContainer>
      </TableFiltersContainer>

      <DroppableContainer>
        <DroppableArea items={items} onOrderChanged={(items) => handleOrderChanged(items as EntrySocietyLists)}>
          {items.map((item, index) => (
            <DraggableItem
              key={item.id}
              id={String(item.id)}
              index={index}
              title={item.title}
              count={item.entries_count}
              onEdit={handleEdit}
              onDelete={setDeletableItemId}
            />
          ))}
        </DroppableArea>

        <EmptyPanel empty={hasNoResults} />
      </DroppableContainer>
    </>
  );
}

gql`
  mutation updateEntrySocietyListOrder($input: UpdateEntrySocietyListOrderInput!) {
    updateEntrySocietyListOrder(input: $input) {
      success
    }
  }

  mutation deleteOneEntrySocietyList($where: EntrySocietyListWhereUniqueInput!) {
    deleteOneEntrySocietyList(where: $where) {
      id
      title
      entries_count
    }
  }

  query entrySocietyLists($where: EntrySocietyListWhereInput, $orderBy: EntrySocietyListOrderByInput) {
    entrySocietyLists(where: $where, orderBy: $orderBy) {
      id
      title
      entries_count
    }
  }
`;

export default EntrySocietyListsTable;
