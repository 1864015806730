import React from 'react';
import { NavLink } from 'react-router-dom';

import PageHeader from '../../../components/system/page-header';
import SEO from '../../../components/system/seo';
import MenuPage from '../../../layout/MenuPage';
import EntriesTable from './EntriesTable';

function EntriesSocietyScreen() {
  const title = "Entries Society";

  return (
    <>
      <SEO title={title} />

      <MenuPage>
        <div className="flex flex-1 flex-col p-4 sm:p-8">
          <PageHeader title={title}>
            <NavLink to="/entries-society/new" className="btn btn-primary ml-auto">
              Add Entry
            </NavLink>
          </PageHeader>

          <EntriesTable />
        </div>
      </MenuPage>
    </>
  );
}

export default EntriesSocietyScreen;
