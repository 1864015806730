import React from 'react';

import { colors } from '../../../utils/theme';

type Props = {
  size?: string | number;
  color?: string;
  className?: string;
};

const DEFAULT_COLOR = colors.gray[600];

function LoadingSpinner(props: Props) {
  const size = props.size || '1.5em';
  const color = props.color || DEFAULT_COLOR;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={props.className} viewBox="0 0 38 38">
      <defs>
        <linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%">
          <stop offset="0%" stopColor={color} stopOpacity="0"></stop>
          <stop offset="63.146%" stopColor={color} stopOpacity="0.631"></stop>
          <stop offset="100%" stopColor={color}></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path stroke="url(#a)" strokeWidth="2" d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            dur="0.9s"
            from="0 18 18"
            repeatCount="indefinite"
            to="360 18 18"
            type="rotate"
          ></animateTransform>
        </path>
        <circle cx="36" cy="18" r="1" fill={color}>
          <animateTransform
            attributeName="transform"
            dur="0.9s"
            from="0 18 18"
            repeatCount="indefinite"
            to="360 18 18"
            type="rotate"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  );
}

export default LoadingSpinner;
