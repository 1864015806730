import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type DeleteOneUserMutationVariables = {
  where: Types.UserWhereUniqueInput;
};


export type DeleteOneUserMutation = { deleteOneUser?: Types.Maybe<(
    Pick<Types.User, 'id' | 'first_name' | 'last_name' | 'username' | 'is_active'>
    & { role?: Types.Maybe<Pick<Types.Role, 'name'>> }
  )> };


export const DeleteOneUserDocument = gql`
    mutation deleteOneUser($where: UserWhereUniqueInput!) {
  deleteOneUser(where: $where) {
    id
    first_name
    last_name
    username
    role {
      name
    }
    is_active
  }
}
    `;
export type DeleteOneUserMutationFn = ApolloReactCommon.MutationFunction<DeleteOneUserMutation, DeleteOneUserMutationVariables>;
export function useDeleteOneUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneUserMutation, DeleteOneUserMutationVariables>(DeleteOneUserDocument, baseOptions);
      }
export type DeleteOneUserMutationHookResult = ReturnType<typeof useDeleteOneUserMutation>;
export type DeleteOneUserMutationResult = ApolloReactCommon.MutationResult<DeleteOneUserMutation>;
export type DeleteOneUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>;