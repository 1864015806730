import gql from 'graphql-tag';
import React from 'react';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';

import AlertSuccess from '../../components/system/alerts/AlertSuccess';
import LoadingButton from '../../components/system/buttons/LoadingButton';
import CompanyCard from '../../components/system/cards/CompanyCard';
import SEO from '../../components/system/seo';
import useForm from '../../hooks/useForm';
import { getMutationFieldErrors } from '../../utils/graphql';
import { useRequestResetPasswordMutation } from './index.operations';

function RequestPasswordResetScreen() {
  const [requestResetPassword, { loading, data }] = useRequestResetPasswordMutation();

  const form = useForm({
    initialValues: { username: '' },
    onSubmit: async (values, actions) => {
      try {
        await requestResetPassword({ variables: { input: { username: values.username } } });
      } catch ({ graphQLErrors }) {
        actions.setErrors(getMutationFieldErrors(graphQLErrors));
      }
    },
    validationSchema: yup.object().shape({
      username: yup.string().email('Invalid e-mail format').required('Email Address is required'),
    }),
  });

  const resetInstructionsSent = !!data?.requestResetPassword.status;

  return (
    <>
      <SEO title="Forgot Password" />

      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-200">
        <div className="max-w-md w-full">
          <CompanyCard>
            <div className="my-12">
              <h1 className="text-3xl font-semibold text-gray-800">Can't sign in?</h1>
              <p className="text-gray-600">Restore access to your account</p>
            </div>

            {resetInstructionsSent ? (
              <div>
                <AlertSuccess
                  title="Instructions Sent"
                  description="Please, check your mailbox for more instructions about how to reset your password."
                />
                <NavLink to="sign-in" className="btn btn-outline-default block text-center mt-5 py-3">
                  OK
                </NavLink>
              </div>
            ) : (
              <form onSubmit={form.handleSubmit}>
                <div className="mb-5">
                  <label htmlFor="username" className="block text-sm text-gray-600 font-medium mb-2">
                    Email Address
                  </label>
                  <input
                    id="username"
                    className="field-input w-full"
                    type="text"
                    value={form.values.username}
                    onChange={form.handleChange}
                  />
                  {!!form.errors.username && !!form.touched.username && (
                    <span className="text-xs text-red-600">{form.errors.username}</span>
                  )}
                </div>

                <div className="flex">
                  <LoadingButton loading={loading} className="btn btn-primary w-full text-base py-3">
                    Send Password Reset Instructions
                  </LoadingButton>
                </div>
              </form>
            )}
          </CompanyCard>

          <NavLink
            to="sign-in"
            className="block text-center m-6 transition duration-200 ease-in-out text-gray-600 hover:text-gray-800"
          >
            Sign in
          </NavLink>
        </div>
      </div>
    </>
  );
}

gql`
  mutation requestResetPassword($input: RequestResetPasswordInput!) {
    requestResetPassword(input: $input) {
      status
    }
  }
`;

export default RequestPasswordResetScreen;
