import React from 'react';

type Props = React.HTMLAttributes<HTMLFormElement>;

function TableFiltersForm({ children, ...props }: React.PropsWithChildren<Props>) {
  return (
    <form className="flex flex-1 flex-col md:flex-row md:ml-auto justify-start items-end" {...props}>
      {children}
    </form>
  )
}

export default TableFiltersForm;
