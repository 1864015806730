import convert from 'convert-units';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AlertDanger from '../../components/system/alerts/AlertDanger';
import TextInput from '../../components/system/inputs/TextInput';
import { useGetSettingsQuery, useUpdateSettingsMutation } from './SettingsForm.operations';

function SettingsForm() {
  const { data, loading: isFetching, error } = useGetSettingsQuery();
  const [updateSettings, { loading: isUpdating }] = useUpdateSettingsMutation({
    onCompleted: () => toast.success('Your updates have been saved!'),
    onError: () => toast.error('Oops, something went wrong.'),
  });

  const getSettings = data?.getSettings;
  const isDisabled = isFetching || isUpdating || !!error;

  const initialValues = {
    outgoing_email_address: getSettings?.outgoing_email_address || '',
    incoming_email_address: getSettings?.incoming_email_address || '',
    session_timeout: !!getSettings?.session_timeout
      ? String(convert(getSettings?.session_timeout).from('s').to('min'))
      : '',
    max_failed_attempts: String(getSettings?.max_failed_attempts || 5),
    google_analytics_tracking_id: getSettings?.google_analytics_tracking_id || '',
  };

  return (
    <div>
      {error && (
        <AlertDanger
          title="Oops!"
          description="Something went wrong while we were loading the settings. Please, try to refresh this page."
          containerClassName="mb-5"
        />
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await updateSettings({
              variables: {
                data: {
                  id: 1,
                  outgoing_email_address: values.outgoing_email_address,
                  incoming_email_address: values.incoming_email_address,
                  session_timeout: convert(Number.parseInt(values.session_timeout)).from('min').to('s'),
                  max_failed_attempts: Number.parseInt(values.max_failed_attempts),
                  google_analytics_tracking_id: values.google_analytics_tracking_id,
                },
              },
            });
          } catch (e) {}
        }}
        validationSchema={yup.object().shape({
          outgoing_email_address: yup.string().email('Invalid e-mail format').nullable(),
          incoming_email_address: yup.string().email('Invalid e-mail format').nullable(),
          session_timeout: yup.number().positive('Negative numbers are not allowed').nullable(),
          max_failed_attempts: yup.number().positive('Negative numbers are not allowed').nullable(),
          google_analytics_tracking_id: yup.string().nullable(),
        })}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="grid grid-cols-1 gap-5">
              <TextInput
                value={props.values.outgoing_email_address}
                name="outgoing_email_address"
                onChange={props.handleChange('outgoing_email_address')}
                placeholder="no-reply@company.com"
                label="Outgoing Email"
                disabled={isDisabled}
                error={!!props.touched.outgoing_email_address ? props.errors.outgoing_email_address : null}
              />
              <TextInput
                value={props.values.incoming_email_address}
                name="incoming_email_address"
                onChange={props.handleChange('incoming_email_address')}
                placeholder="support@company.com"
                label="Incoming Email"
                disabled={isDisabled}
                error={!!props.touched.incoming_email_address ? props.errors.incoming_email_address : null}
              />
              <TextInput
                value={props.values.session_timeout}
                name="session_timeout"
                onChange={props.handleChange('session_timeout')}
                placeholder="3600"
                label="Session Timeout (in minutes)"
                disabled={isDisabled}
                error={!!props.touched.session_timeout ? props.errors.session_timeout : null}
              />
              <TextInput
                value={props.values.max_failed_attempts}
                name="max_failed_attempts"
                onChange={props.handleChange('max_failed_attempts')}
                placeholder="5"
                label="Max. # Failed Attempts"
                disabled={isDisabled}
                error={!!props.touched.max_failed_attempts ? props.errors.max_failed_attempts : null}
              />
              <TextInput
                value={props.values.google_analytics_tracking_id}
                name="google_analytics_tracking_id"
                onChange={props.handleChange('google_analytics_tracking_id')}
                placeholder=""
                label="Google Analytics Tracking ID"
                disabled={isDisabled}
                error={!!props.touched.google_analytics_tracking_id ? props.errors.google_analytics_tracking_id : null}
              />
            </div>
            <div className="flex justify-end mt-6 pt-6 border-t-2 border-gray-200">
              <input value="Save" type="submit" className="btn btn-primary px-6" disabled={isDisabled} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

gql`
  query getSettings {
    getSettings(where: { id: 1 }) {
      ...SystemSettings
    }
  }

  mutation updateSettings($data: SettingUpdateInput!) {
    updateSettings(data: $data, where: { id: 1 }) {
      ...SystemSettings
    }
  }

  fragment SystemSettings on Setting {
    id
    outgoing_email_address
    incoming_email_address
    max_failed_attempts
    session_timeout
    google_analytics_tracking_id
  }
`;

export default SettingsForm;
