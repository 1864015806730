import React, { ButtonHTMLAttributes } from 'react';
import { FiPrinter } from 'react-icons/fi';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

function PrintButton({ className, ...props }: Props) {
  return (
    <button
      className="flex items-center rounded-md border border-gray-400 px-4 py-2 text-sm leading-5 font-medium text-gray-600 focus:outline-none hover:text-gray-700"
      onClick={window.print}
      {...props}
    >
      <FiPrinter className="mr-1" />
      Print
    </button>
  );
}

export default PrintButton;
