import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

export type SelectFilterOption = {
  value: string | number;
  label: string;
};

type Props = {
  id: string;
  label: string;
  options: SelectFilterOption[];
  value?: string | number;
  width?: '32' | '56' | '64' | 'auto' | 'full';
  onChange: (filterId: string, event: React.ChangeEvent<HTMLSelectElement>) => void;
};

function SelectFilter(props: Props) {
  const width = props.width || '32';

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(props.id, event);
  }

  return (
    <div className="flex flex-col mb-4 w-full md:w-auto md:mb-0 md:mr-4">
      <label className="text-sm text-gray-600 font-medium mb-1">{props.label}</label>

      <div className="flex relative">
        <select
          className={`field-input bg-white w-full shadow pr-6 md:w-${width}`}
          onChange={handleChange}
          value={props.value}
        >
          {props.options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="flex absolute items-center justify-center right-0 inset-y-0 mr-2 pointer-events-none text-gray-500">
          <FiChevronDown />
        </div>
      </div>
    </div>
  );
}

export default SelectFilter;
