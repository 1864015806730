import React, { useEffect, useState } from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { IconType } from 'react-icons/lib/cjs';
import { NavLink, useLocation } from 'react-router-dom';

type ChildNavLink = {
  to: string;
  title: string;
};

type MainNavLink = {
  title: string;
  Icon: IconType;
};

type Props = MainNavLink & { options?: ChildNavLink[] };

function MenuNavLinkWithOptions(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!!props.options) {
      props.options.some((option) => {
        if (option.to === location.pathname) {
          setIsOpen(true);
          return true;
        }

        return false;
      });
    }
  }, [location, props.options]);

  function toggleOptions() {
    setIsOpen(!isOpen);
  }

  const DropdownIconComponent = isOpen ? FiChevronDown : FiChevronRight;
  const textStyle = ` hover:text-white ${isOpen ? 'text-white' : 'text-gray-500'}`;

  return (
    <div className={`flex flex-col rounded-md tracking-wide hover:bg-gray-900 ${isOpen ? 'bg-gray-900' : ''}`}>
      <div
        role="button"
        className={`flex flex-row items-center px-3 py-2 hover:text-white${textStyle}`}
        onClick={toggleOptions}
      >
        <div className="flex flex-row items-center">
          <props.Icon />
          <span className="ml-2 text-sm">{props.title}</span>
        </div>

        <DropdownIconComponent className="ml-auto opacity-50" />
      </div>

      {isOpen && !!props.options && (
        <ul className="px-3 pb-3 text-sm">
          {props.options.map((option) => (
            <li>
              <NavLink
                key={option.to}
                to={option.to}
                className={`flex items-center pb-1 text-gray-500${textStyle}`}
                activeStyle={{ color: 'white' }}
              >
                <span className="ml-6">{option.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MenuNavLinkWithOptions;
