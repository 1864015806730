import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UpdateEntryListOrderMutationVariables = {
  input: Types.UpdateEntryListOrderInput;
};


export type UpdateEntryListOrderMutation = { updateEntryListOrder: Pick<Types.UpdateEntryListOrder, 'success'> };

export type DeleteOneEntryListMutationVariables = {
  where: Types.EntryListWhereUniqueInput;
};


export type DeleteOneEntryListMutation = { deleteOneEntryList?: Types.Maybe<Pick<Types.EntryList, 'id' | 'title' | 'entries_count'>> };

export type EntryListsQueryVariables = {
  where?: Types.Maybe<Types.EntryListWhereInput>;
  orderBy?: Types.Maybe<Types.EntryListOrderByInput>;
};


export type EntryListsQuery = { entryLists: Array<Pick<Types.EntryList, 'id' | 'title' | 'entries_count'>> };


export const UpdateEntryListOrderDocument = gql`
    mutation updateEntryListOrder($input: UpdateEntryListOrderInput!) {
  updateEntryListOrder(input: $input) {
    success
  }
}
    `;
export type UpdateEntryListOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateEntryListOrderMutation, UpdateEntryListOrderMutationVariables>;
export function useUpdateEntryListOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntryListOrderMutation, UpdateEntryListOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntryListOrderMutation, UpdateEntryListOrderMutationVariables>(UpdateEntryListOrderDocument, baseOptions);
      }
export type UpdateEntryListOrderMutationHookResult = ReturnType<typeof useUpdateEntryListOrderMutation>;
export type UpdateEntryListOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEntryListOrderMutation>;
export type UpdateEntryListOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntryListOrderMutation, UpdateEntryListOrderMutationVariables>;
export const DeleteOneEntryListDocument = gql`
    mutation deleteOneEntryList($where: EntryListWhereUniqueInput!) {
  deleteOneEntryList(where: $where) {
    id
    title
    entries_count
  }
}
    `;
export type DeleteOneEntryListMutationFn = ApolloReactCommon.MutationFunction<DeleteOneEntryListMutation, DeleteOneEntryListMutationVariables>;
export function useDeleteOneEntryListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneEntryListMutation, DeleteOneEntryListMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneEntryListMutation, DeleteOneEntryListMutationVariables>(DeleteOneEntryListDocument, baseOptions);
      }
export type DeleteOneEntryListMutationHookResult = ReturnType<typeof useDeleteOneEntryListMutation>;
export type DeleteOneEntryListMutationResult = ApolloReactCommon.MutationResult<DeleteOneEntryListMutation>;
export type DeleteOneEntryListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneEntryListMutation, DeleteOneEntryListMutationVariables>;
export const EntryListsDocument = gql`
    query entryLists($where: EntryListWhereInput, $orderBy: EntryListOrderByInput) {
  entryLists(where: $where, orderBy: $orderBy) {
    id
    title
    entries_count
  }
}
    `;
export function useEntryListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryListsQuery, EntryListsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryListsQuery, EntryListsQueryVariables>(EntryListsDocument, baseOptions);
      }
export function useEntryListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryListsQuery, EntryListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryListsQuery, EntryListsQueryVariables>(EntryListsDocument, baseOptions);
        }
export type EntryListsQueryHookResult = ReturnType<typeof useEntryListsQuery>;
export type EntryListsLazyQueryHookResult = ReturnType<typeof useEntryListsLazyQuery>;
export type EntryListsQueryResult = ApolloReactCommon.QueryResult<EntryListsQuery, EntryListsQueryVariables>;