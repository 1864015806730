import React from 'react'
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

type Props = {
  items: unknown[],
  onOrderChanged: (items: unknown[]) => void;
  className?: string;
}

function DroppableArea(props: React.PropsWithChildren<Props>) {

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    props.onOrderChanged(reorderItems(props.items, source.index, destination.index));
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={props.className || "p-4" }
          >
            {props.children}

            {provided.placeholder}
          </div>
        )}
        </Droppable>
    </DragDropContext>
  )
}

function reorderItems(items: unknown[], startIndex: number, endIndex: number) {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};


export default DroppableArea;
