import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import AddUserForm from './AddUserForm';

function AddUserScreen() {
  const history = useHistory();

  const title = 'Add User';
  const breadcrumbs = [
    { to: '/users/', label: 'Users' },
    { to: '/users/new', label: 'Add' },
  ];

  return (
    <>
      <Helmet>
        <title>Add User - HDI Admin System</title>
      </Helmet>
      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <AddUserForm afterSubmit={() => history.push('/users')} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default AddUserScreen;
