import exportFromJSON from 'export-from-json';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import ExportDropdownButton from '../../../components/system/buttons/custom/ExportDropdownButton';
import PrintButton from '../../../components/system/buttons/custom/PrintButton';
import FilterButton from '../../../components/system/buttons/FilterButton';
import SearchFilter from '../../../components/system/filters/custom/SearchFilter';
import EmptyPanel from '../../../components/system/panel/EmptyPanel';
import TableFiltersActionsContainer from '../../../components/system/table/TableFiltersActionsContainer';
import TableFiltersContainer from '../../../components/system/table/TableFiltersContainer';
import TableFiltersForm from '../../../components/system/table/TableFiltersForm';
import TableHeader, { ColumnOrderedByType } from '../../../components/system/table/TableHeader';
import TablePanelContainer from '../../../components/system/table/TablePanelContainer';
import useDebounce from '../../../hooks/useDebounce';
import { OrderByArg, UserWhereInput } from '../../../types/graphql';
import { useUsersQuery } from './UsersTable.operations';
import UsersTableItem from './UsersTableItem';

const DEFAULT_ORDER: ColumnOrderedByType = { id: OrderByArg['Asc'] };

function UsersTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentOrder, setCurrentOrder] = useState(DEFAULT_ORDER);
  const debouncedOrder = useDebounce(currentOrder, 500);
  const { data, loading, error, refetch } = useUsersQuery({ variables: { orderBy: DEFAULT_ORDER } });

  const users = data?.users || [];
  const hasNoResults = !loading && !!users && users.length <= 0;

  useEffect(() => {
    refetch({ orderBy: debouncedOrder }).catch(() => {});
  }, [debouncedOrder, refetch]);

  const columns = [
    { id: 'id', name: 'id' },
    { id: 'first_name', name: 'First Name' },
    { id: 'last_name', name: 'Last Name' },
    { id: 'username', name: 'Username' },
    { id: 'role_id', name: 'Role' },
    { id: 'is_active', name: 'Is Active' },
    { id: '' },
  ];

  async function buildExportablePayload() {
    const data: {}[] = [];
    const fileName = 'users';

    users.forEach((user) => {
      data.push({
        ID: user.id,
        'First name': user.first_name,
        'Last name': user.last_name,
        Username: user.username,
        Role: user.role?.name,
        'Is active': user.is_active ? 'Yes' : 'No',
      });
    });

    return { data, fileName };
  }

  function handleFilterChange(filterId: string, value: any) {
    setSearchTerm(value);
  }

  function handleFilter(event?: React.FormEvent) {
    event?.preventDefault();

    if (!searchTerm) {
      return refetch({ where: {} }).catch(() => {});
    }

    const searchTermAsInt = parseInt(searchTerm, 10);
    const conditions: UserWhereInput[] = [
      { first_name: { contains: searchTerm } },
      { last_name: { contains: searchTerm } },
      { username: { contains: searchTerm } },
      { role: { name: { contains: searchTerm } } },
    ];

    if (searchTermAsInt) {
      conditions.push({ id: { equals: searchTermAsInt } });
    }

    refetch({ where: { OR: conditions } });
  }

  async function handleExportAsCSV() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'csv' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  async function handleExportAsXLS() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'xls' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the users list. Please, try to refresh this page."
          containerClassName="shadow"
        />
      )}

      <TableFiltersContainer>
        <TableFiltersForm onSubmit={handleFilter}>
          <SearchFilter width="64" onChange={handleFilterChange} />

          <FilterButton onClick={handleFilter} />
        </TableFiltersForm>

        <TableFiltersActionsContainer loading={loading}>
          <PrintButton />
          <ExportDropdownButton onClickDownloadCSV={handleExportAsCSV} onClickDownloadExcel={handleExportAsXLS} />
        </TableFiltersActionsContainer>
      </TableFiltersContainer>

      <TablePanelContainer>
        <table className="table-fixed min-w-full">
          <TableHeader columns={columns} orderedBy={currentOrder} onChangeOrder={setCurrentOrder} />
          <tbody className="bg-white">
            {users.map((user) => (
              <UsersTableItem key={user.id} user={user} />
            ))}
          </tbody>
        </table>

        <EmptyPanel empty={hasNoResults} />
      </TablePanelContainer>
    </>
  );
}

gql`
  query users($where: UserWhereInput, $orderBy: UserOrderByInput) {
    users(where: $where, orderBy: $orderBy) {
      id
      first_name
      last_name
      is_active
      username
      role {
        name
      }
    }
  }
`;

export default UsersTable;
