import React from 'react';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';

import { OrderByArg } from '../../../types/graphql';

export type OrderedBy = { [columnId: string]: OrderByArg };

type Props = {
  columnId: string;
  columnName?: string;
  currentOrder?: OrderedBy;
  extraClassNames?: string;
  onClick?: (newOrder: OrderedBy) => void;
};

const DEFAULT_ORDER = OrderByArg['Asc'];

function TableHeaderCell({ columnId, columnName, currentOrder, extraClassNames, onClick }: Props) {
  const classNames = extraClassNames ? ` ${extraClassNames}` : '';

  function handlePress() {
    if (!!onClick) {
      let newOrder: OrderedBy = { [columnId]: DEFAULT_ORDER };
      const currentOrderingCriteria = currentOrder?.[columnId];

      if (currentOrderingCriteria) {
        newOrder = {
          [columnId]: currentOrderingCriteria === OrderByArg['Asc'] ? OrderByArg['Desc'] : OrderByArg['Asc'],
        };
      }

      onClick(newOrder);
    }
  }

  function renderOrderIndicator() {
    if (!columnName) {
      return null;
    }

    const currentOrderingCriteria = currentOrder?.[columnId];
    const defaultStyle = 'text-sm flex-shrink-0 ml-2';

    if (currentOrderingCriteria) {
      return currentOrderingCriteria === 'asc' ? (
        <TiArrowSortedDown className={defaultStyle} />
      ) : (
        <TiArrowSortedUp className={defaultStyle} />
      );
    }

    return <TiArrowUnsorted className={`${defaultStyle}  opacity-50`} />;
  }

  return (
    <th
      className={`table-th border-gray-200 bg-white text-left text-gray-500 px-4 py-3 ${classNames}`}
      onClick={handlePress}
      role="button"
    >
      <div className="flex items-center justify-between">
        {columnName} {renderOrderIndicator()}
      </div>
    </th>
  );
}

export default TableHeaderCell;
