import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import RequestPasswordResetScreen from '../screens/request-password-reset';
import ResetPasswordScreen from '../screens/reset-password';
import SettingsScreen from '../screens/settings';
import SignInScreen from '../screens/sign-in';
import SignOutScreen from '../screens/sign-out';
import EntriesRouter from './EntriesRouter';
import EntriesSocietyRouter from './EntriesSocietyRouter';
import ListsRouter from './ListsRouter';
import ListsSocietyRouter from './ListsSocietyRouter';
import PrivateRoute from './PrivateRoute';
import UsersRouter from './UsersRouter';

function MainRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/users/reset-password">
          <ResetPasswordScreen />
        </Route>

        <PrivateRoute exact path="/">
          <Redirect to="/entries" />
        </PrivateRoute>

        <PrivateRoute path="/entries">
          <EntriesRouter />
        </PrivateRoute>

        <PrivateRoute path="/entries-society">
          <EntriesSocietyRouter />
        </PrivateRoute>

        <PrivateRoute path="/lists">
          <ListsRouter />
        </PrivateRoute>

        <PrivateRoute path="/lists-society">
          <ListsSocietyRouter />
        </PrivateRoute>

        <PrivateRoute path="/users">
          <UsersRouter />
        </PrivateRoute>

        <PrivateRoute exact path="/settings">
          <SettingsScreen />
        </PrivateRoute>

        <Route exact path="/request-password-reset">
          <RequestPasswordResetScreen />
        </Route>

        <Route exact path="/sign-in">
          <SignInScreen />
        </Route>

        <Route exact path="/sign-out">
          <SignOutScreen />
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
