import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import UserForm from '../../../components/system/forms/UserForm';
import { useEditUserMutation, useRolesQuery, useUserQuery } from './EditUserForm.operations';

type Props = {
  userId: string;
};

function EditUserForm(props: Props) {
  const { data: rolesData, loading: isRolesLoading } = useRolesQuery();
  const { data: userData, loading: isUserLoading, error: errorFetchingUser } = useUserQuery({
    variables: { where: { id: parseInt(props.userId) } },
  });
  const [editUser] = useEditUserMutation({ refetchQueries: ['users'], awaitRefetchQueries: true });

  const roles = rolesData?.roles || [];
  const user = userData?.user;

  const validationSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    username: yup.string().email('Invalid email format').required('Username is required'),
    role: yup.string().required('Role is required'),
    is_active: yup.boolean(),
  });

  const initialValues = {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    username: user?.username || '',
    password: '',
    role: user?.role?.name || '',
    is_active: user?.is_active || false,
  };

  async function handleSubmit(values: typeof initialValues) {
    try {
      await editUser({
        variables: {
          input: {
            id: props.userId,
            first_name: values.first_name,
            last_name: values.last_name,
            username: values.username,
            password: values.password,
            is_active: values.is_active,
            role: values.role,
          },
        },
      });

      toast.success(`${values.first_name}'s account has been updated!`);
    } catch {
      toast.error('Oops, something went wrong.');
    }
  }

  return (
    <>
      {errorFetchingUser && (
        <AlertDanger
          description="An error occurred while loading the user. Please, try to refresh this page."
          containerClassName="mb-8"
        />
      )}

      <UserForm
        initialValues={initialValues}
        roles={roles}
        isDisabled={isUserLoading || !!errorFetchingUser}
        isRolesLoading={isRolesLoading}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      />
    </>
  );
}

gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      ...ReturnUser
    }
  }

  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      user {
        ...ReturnUser
      }
    }
  }

  fragment ReturnUser on User {
    id
    first_name
    last_name
    username
    role {
      name
    }
    is_active
  }

  query roles {
    roles {
      name
    }
  }
`;

export default EditUserForm;
