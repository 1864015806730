import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import EntryListForm from '../../../components/system/forms/EntryListForm';
import { useCreateOneEntrySocietyListMutation } from './AddListForm.operations';

type Props = {
  afterSubmit: () => void;
};

function AddListForm(props: Props) {
  const [createEntrySocietyList] = useCreateOneEntrySocietyListMutation({ refetchQueries: ['entrySocietyLists'], awaitRefetchQueries: true });
  const initialValues = { title: '' };

  return (
    <>
      <EntryListForm
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await createEntrySocietyList({ variables: { input: { title: values.title } } });
            toast.success(`${values.title} has been created!`);

            props.afterSubmit();
          } catch {
            toast.error('Oops, something went wrong.');
          }
        }}
      />
    </>
  );
}

gql`
  mutation createOneEntrySocietyList($input: CreateEntrySocietyListInput!) {
    createOneEntrySocietyList(input: $input) {
      entrySocietyList {
        id
        title
      }
    }
  }
`;

export default AddListForm;
