import React, { ReactNode } from 'react';

import Card from '../../components/system/cards/Card';
import PageHeader, { BreadcrumbsType } from '../../components/system/page-header';

type Props = {
  title: string;
  breadcrumbs?: BreadcrumbsType;
  children?: ReactNode | ReactNode[] | null;
};

function BasicTitlePage(props: Props) {
  return (
    <div className="flex flex-1 flex-col p-4 sm:p-8">
      <PageHeader title={props.title} breadcrumbs={props.breadcrumbs} />

      <Card className="p-4 sm:p-8">{props.children}</Card>
    </div>
  );
}

export default BasicTitlePage;
