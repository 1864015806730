import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SEO from '../../components/system/seo';
import AuthService from '../../services/AuthService';

function SignOutScreen() {
  const history = useHistory();

  useEffect(() => {
    AuthService.removeToken();

    history.replace('/sign-in');
  }, [history]);

  return <SEO title="Sign Out" />;
}

export default SignOutScreen;
