import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import useForm from '../../../hooks/useForm';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';

type ListType = {
  id: number;
  title: string;
}

export type EntryFormValues = {
  title: string;
  list_id: string | number;
};

type Props = {
  initialValues: EntryFormValues;
  initialList?: ListType;
  lists: ListType[];
  isDisabled?: boolean;
  isLoadingLists?: boolean;
  onSubmit: (values: EntryFormValues, formikHelpers: FormikHelpers<EntryFormValues>) => void | Promise<any>;
};

function EntryForm(props: Props) {
  const form = useForm<EntryFormValues>({
    enableReinitialize: true,
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    validationSchema: validationSchema,
  });

  const isDisabled = props.isDisabled || form.isSubmitting;
  const initialList = props.initialList;

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <div className="grid grid-cols-1 gap-5">
          <TextInput
            value={form.values.title}
            name="title"
            onChange={form.handleChange}
            label="Title"
            disabled={isDisabled}
            error={!!form.touched.title ? form.errors.title : null}
          />
          
          <SelectInput
            key={initialList?.id}
            name="list_id"
            label="List"
            defaultValue={initialList ? { label: initialList.title, value: initialList.id } : undefined}
            options={props.lists.map((list) => ({
              value: list.id,
              label: list.title,
            }))}
            isLoading={props.isLoadingLists}
            isDisabled={props.isLoadingLists || isDisabled}
            onChange={(value) => form.setFieldValue('list_id', value)}
            error={!!form.touched.list_id ? form.errors.list_id : null}
          />
        </div>
        <div className="flex justify-between mt-6 pt-6 border-t-2 border-gray-200">
          <input value="Save" type="submit" className="btn btn-primary px-6 ml-auto" disabled={isDisabled} />
        </div>
      </form>
    </>
  );
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  list_id: yup.string().required('List is required'),
});

export default EntryForm;
