import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntryListsOnCreateQueryVariables = {};


export type EntryListsOnCreateQuery = { entryLists: Array<Pick<Types.EntryList, 'id' | 'title'>> };

export type CreateOneEntryMutationVariables = {
  input: Types.CreateEntryInput;
};


export type CreateOneEntryMutation = { createOneEntry: { entry: Pick<Types.Entry, 'id' | 'title'> } };


export const EntryListsOnCreateDocument = gql`
    query entryListsOnCreate {
  entryLists {
    id
    title
  }
}
    `;
export function useEntryListsOnCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryListsOnCreateQuery, EntryListsOnCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryListsOnCreateQuery, EntryListsOnCreateQueryVariables>(EntryListsOnCreateDocument, baseOptions);
      }
export function useEntryListsOnCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryListsOnCreateQuery, EntryListsOnCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryListsOnCreateQuery, EntryListsOnCreateQueryVariables>(EntryListsOnCreateDocument, baseOptions);
        }
export type EntryListsOnCreateQueryHookResult = ReturnType<typeof useEntryListsOnCreateQuery>;
export type EntryListsOnCreateLazyQueryHookResult = ReturnType<typeof useEntryListsOnCreateLazyQuery>;
export type EntryListsOnCreateQueryResult = ApolloReactCommon.QueryResult<EntryListsOnCreateQuery, EntryListsOnCreateQueryVariables>;
export const CreateOneEntryDocument = gql`
    mutation createOneEntry($input: CreateEntryInput!) {
  createOneEntry(input: $input) {
    entry {
      id
      title
    }
  }
}
    `;
export type CreateOneEntryMutationFn = ApolloReactCommon.MutationFunction<CreateOneEntryMutation, CreateOneEntryMutationVariables>;
export function useCreateOneEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneEntryMutation, CreateOneEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneEntryMutation, CreateOneEntryMutationVariables>(CreateOneEntryDocument, baseOptions);
      }
export type CreateOneEntryMutationHookResult = ReturnType<typeof useCreateOneEntryMutation>;
export type CreateOneEntryMutationResult = ApolloReactCommon.MutationResult<CreateOneEntryMutation>;
export type CreateOneEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneEntryMutation, CreateOneEntryMutationVariables>;