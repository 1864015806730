import React from 'react';

type Option = {
  value: string | number;
  label: string;
};

type Props = {
  id?: string;
  label?: string;
  value?: string | number;
  width?: '32' | '56' | '64' | 'auto' | 'full';
  onChange: (filterId: string, value: string) => void;
};

function SearchFilter(props: Props) {
  const id = props.id || 'searchTerm';
  const label = props.label || 'Search';
  const width = props.width || '56';

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(id, event.target.value);
  }

  return (
    <div className="flex flex-col mb-4 w-full md:w-auto md:mb-0 md:mr-4">
      <label className="text-sm text-gray-600 font-medium mb-1" htmlFor="searchInput">
        {label}
      </label>
      <input
        type="text"
        id="searchInput"
        className={`field-input bg-white w-full shadow md:w-${width}`}
        onChange={handleChange}
        value={props.value}
      />
    </div>
  );
}

export default SearchFilter;
