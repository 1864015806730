import React from 'react';

import Loader from '../loading-spinner';

type Props = {
  loading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function TableFiltersActionsContainer({ children, loading, ...props }: React.PropsWithChildren<Props>) {
  return (
    <div className="grid-flow-col gap-2 items-center hidden md:grid" {...props}>
      {loading && <Loader size={26} className="mr-1" />}

      {children}
    </div>
  )
}

export default TableFiltersActionsContainer;
