import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import useForm from '../../../hooks/useForm';
import TextInput from '../inputs/TextInput';

export type EntryListFormValues = {
  title: string;
};

type Props = {
  initialValues: EntryListFormValues;
  isDisabled?: boolean;
  onSubmit: (values: EntryListFormValues, formikHelpers: FormikHelpers<EntryListFormValues>) => void | Promise<any>;
};

function EntryListForm(props: Props) {
  const form = useForm<EntryListFormValues>({
    enableReinitialize: true,
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    validationSchema: validationSchema,
  });

  const isDisabled = props.isDisabled || form.isSubmitting;

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <div className="grid grid-cols-1 gap-5">
          <TextInput
            value={form.values.title}
            name="title"
            onChange={form.handleChange}
            label="Title"
            disabled={isDisabled}
            error={!!form.touched.title ? form.errors.title : null}
          />
        </div>
        <div className="flex justify-between mt-6 pt-6 border-t-2 border-gray-200">
          <input value="Save" type="submit" className="btn btn-primary px-6 ml-auto" disabled={isDisabled} />
        </div>
      </form>
    </>
  );
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
});

export default EntryListForm;
