import gql from 'graphql-tag';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import AlertSuccess from '../../components/system/alerts/AlertSuccess';
import LoadingButton from '../../components/system/buttons/LoadingButton';
import CompanyCard from '../../components/system/cards/CompanyCard';
import SEO from '../../components/system/seo';
import useForm from '../../hooks/useForm';
import { getMutationFieldErrors } from '../../utils/graphql';
import { useResetPasswordMutation } from './index.operations';

function ResetPasswordScreen() {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const token = queryParams.get('token') || '';

  const [resetPassword, { loading, data }] = useResetPasswordMutation();
  const form = useForm({
    initialValues: { password: '', passwordConfirmation: '' },
    onSubmit: async (values, actions) => {
      try {
        await resetPassword({ variables: { input: { token, newPassword: values.password } } });
      } catch ({ graphQLErrors }) {
        actions.setErrors(getMutationFieldErrors(graphQLErrors));
      }
    },
    validationSchema: yup.object().shape({
      password: yup.string().required('Password is required'),
      passwordConfirmation: yup
        .string()
        .required('Password confirmation is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    }),
  });

  function navigatoToSignUpScreen() {
    history.replace('/sign-in');
  }

  const passwordUpdated = !!data?.resetPassword.user;

  if (!token) {
    navigatoToSignUpScreen();
  }

  return (
    <>
      <SEO title="Reset Password" />

      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-200">
        <div className="max-w-md w-full">
          <CompanyCard>
            <div className="my-12">
              <h1 className="text-3xl font-semibold text-gray-800">Reset Password</h1>
              <p className="text-gray-600">Enter a new password for your account</p>
            </div>

            {passwordUpdated ? (
              <div>
                <AlertSuccess
                  title="Password Updated"
                  description="You're now able to sign in using your new password."
                />
                <button
                  className="btn btn-outline-default text-center mt-5 py-3 w-full"
                  onClick={navigatoToSignUpScreen}
                >
                  OK
                </button>
              </div>
            ) : (
              <form onSubmit={form.handleSubmit}>
                <div className="mb-5">
                  <label htmlFor="password" className="block text-sm text-gray-600 font-medium mb-2">
                    New Password
                  </label>
                  <input
                    id="password"
                    className="field-input w-full"
                    type="password"
                    value={form.values.password}
                    onChange={form.handleChange}
                  />
                  {!!form.errors.password && !!form.touched.password && (
                    <span className="text-xs text-red-600">{form.errors.password}</span>
                  )}
                </div>

                <div className="mb-5">
                  <label htmlFor="passwordConfirmation" className="block text-sm text-gray-600 font-medium mb-2">
                    Re-enter New Password
                  </label>
                  <input
                    id="passwordConfirmation"
                    className="field-input w-full"
                    type="password"
                    value={form.values.passwordConfirmation}
                    onChange={form.handleChange}
                  />
                  {!!form.errors.passwordConfirmation && !!form.touched.passwordConfirmation && (
                    <span className="text-xs text-red-600">{form.errors.passwordConfirmation}</span>
                  )}
                </div>

                <div className="flex">
                  <LoadingButton loading={loading} className="btn-primary w-full rounded-md text-sm font-semibold py-3">
                    Confirm
                  </LoadingButton>
                </div>
              </form>
            )}
          </CompanyCard>

          <button
            className="text-center my-6 transition duration-200 ease-in-out text-gray-600 hover:text-gray-800 w-full"
            onClick={navigatoToSignUpScreen}
          >
            Sign in
          </button>
        </div>
      </div>
    </>
  );
}

gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      user {
        id
      }
    }
  }
`;

export default ResetPasswordScreen;
