import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EntrySocietyListScreen from '../screens/entry-society-lists/list';
import AddEntrySocietyListScreen from '../screens/entry-society-lists/new';
import EditEntrySocietyListScreen from '../screens/entry-society-lists/edit';

function ListsSocietyRouter() {
  return (
    <Switch>
      <Route exact path="/lists-society">
        <EntrySocietyListScreen />
      </Route>

      <Route exact path="/lists-society/new">
        <AddEntrySocietyListScreen />
      </Route>

      <Route exact path="/lists-society/:entryListId">
        <EditEntrySocietyListScreen />
      </Route>
    </Switch>
  );
}

export default ListsSocietyRouter;
