import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UpdateEntrySocietyOrderMutationVariables = {
  input: Types.UpdateEntrySocietyOrderInput;
};


export type UpdateEntrySocietyOrderMutation = { updateEntrySocietyOrder: Pick<Types.UpdateEntrySocietyOrder, 'success'> };

export type DeleteOneEntrySocietyMutationVariables = {
  where: Types.EntrySocietyWhereUniqueInput;
};


export type DeleteOneEntrySocietyMutation = { deleteOneEntrySociety?: Types.Maybe<Pick<Types.EntrySociety, 'id' | 'title'>> };

export type EntrySocietyListsOnListQueryVariables = {};


export type EntrySocietyListsOnListQuery = { entrySocietyLists: Array<Pick<Types.EntrySocietyList, 'id' | 'title'>> };

export type EntrySocietiesQueryVariables = {
  where?: Types.Maybe<Types.EntrySocietyWhereInput>;
  orderBy?: Types.Maybe<Types.EntrySocietyOrderByInput>;
};


export type EntrySocietiesQuery = { entrySocieties: Array<(
    Pick<Types.EntrySociety, 'id' | 'title'>
    & { list: Pick<Types.EntrySocietyList, 'id' | 'title'> }
  )> };


export const UpdateEntrySocietyOrderDocument = gql`
    mutation updateEntrySocietyOrder($input: UpdateEntrySocietyOrderInput!) {
  updateEntrySocietyOrder(input: $input) {
    success
  }
}
    `;
export type UpdateEntrySocietyOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateEntrySocietyOrderMutation, UpdateEntrySocietyOrderMutationVariables>;
export function useUpdateEntrySocietyOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntrySocietyOrderMutation, UpdateEntrySocietyOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntrySocietyOrderMutation, UpdateEntrySocietyOrderMutationVariables>(UpdateEntrySocietyOrderDocument, baseOptions);
      }
export type UpdateEntrySocietyOrderMutationHookResult = ReturnType<typeof useUpdateEntrySocietyOrderMutation>;
export type UpdateEntrySocietyOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEntrySocietyOrderMutation>;
export type UpdateEntrySocietyOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntrySocietyOrderMutation, UpdateEntrySocietyOrderMutationVariables>;
export const DeleteOneEntrySocietyDocument = gql`
    mutation deleteOneEntrySociety($where: EntrySocietyWhereUniqueInput!) {
  deleteOneEntrySociety(where: $where) {
    id
    title
  }
}
    `;
export type DeleteOneEntrySocietyMutationFn = ApolloReactCommon.MutationFunction<DeleteOneEntrySocietyMutation, DeleteOneEntrySocietyMutationVariables>;
export function useDeleteOneEntrySocietyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneEntrySocietyMutation, DeleteOneEntrySocietyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneEntrySocietyMutation, DeleteOneEntrySocietyMutationVariables>(DeleteOneEntrySocietyDocument, baseOptions);
      }
export type DeleteOneEntrySocietyMutationHookResult = ReturnType<typeof useDeleteOneEntrySocietyMutation>;
export type DeleteOneEntrySocietyMutationResult = ApolloReactCommon.MutationResult<DeleteOneEntrySocietyMutation>;
export type DeleteOneEntrySocietyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneEntrySocietyMutation, DeleteOneEntrySocietyMutationVariables>;
export const EntrySocietyListsOnListDocument = gql`
    query entrySocietyListsOnList {
  entrySocietyLists {
    id
    title
  }
}
    `;
export function useEntrySocietyListsOnListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyListsOnListQuery, EntrySocietyListsOnListQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyListsOnListQuery, EntrySocietyListsOnListQueryVariables>(EntrySocietyListsOnListDocument, baseOptions);
      }
export function useEntrySocietyListsOnListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyListsOnListQuery, EntrySocietyListsOnListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyListsOnListQuery, EntrySocietyListsOnListQueryVariables>(EntrySocietyListsOnListDocument, baseOptions);
        }
export type EntrySocietyListsOnListQueryHookResult = ReturnType<typeof useEntrySocietyListsOnListQuery>;
export type EntrySocietyListsOnListLazyQueryHookResult = ReturnType<typeof useEntrySocietyListsOnListLazyQuery>;
export type EntrySocietyListsOnListQueryResult = ApolloReactCommon.QueryResult<EntrySocietyListsOnListQuery, EntrySocietyListsOnListQueryVariables>;
export const EntrySocietiesDocument = gql`
    query entrySocieties($where: EntrySocietyWhereInput, $orderBy: EntrySocietyOrderByInput) {
  entrySocieties(where: $where, orderBy: $orderBy) {
    id
    title
    list {
      id
      title
    }
  }
}
    `;
export function useEntrySocietiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietiesQuery, EntrySocietiesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietiesQuery, EntrySocietiesQueryVariables>(EntrySocietiesDocument, baseOptions);
      }
export function useEntrySocietiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietiesQuery, EntrySocietiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietiesQuery, EntrySocietiesQueryVariables>(EntrySocietiesDocument, baseOptions);
        }
export type EntrySocietiesQueryHookResult = ReturnType<typeof useEntrySocietiesQuery>;
export type EntrySocietiesLazyQueryHookResult = ReturnType<typeof useEntrySocietiesLazyQuery>;
export type EntrySocietiesQueryResult = ApolloReactCommon.QueryResult<EntrySocietiesQuery, EntrySocietiesQueryVariables>;