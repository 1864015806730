import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UsersQueryVariables = {
  where?: Types.Maybe<Types.UserWhereInput>;
  orderBy?: Types.Maybe<Types.UserOrderByInput>;
};


export type UsersQuery = { users: Array<(
    Pick<Types.User, 'id' | 'first_name' | 'last_name' | 'is_active' | 'username'>
    & { role?: Types.Maybe<Pick<Types.Role, 'name'>> }
  )> };


export const UsersDocument = gql`
    query users($where: UserWhereInput, $orderBy: UserOrderByInput) {
  users(where: $where, orderBy: $orderBy) {
    id
    first_name
    last_name
    is_active
    username
    role {
      name
    }
  }
}
    `;
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;