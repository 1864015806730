import gql from 'graphql-tag';
import React, { useState } from 'react';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import YesNoLabel from '../../../components/system/label/YesNoLabel';
import DestructiveAlertModal from '../../../components/system/modals/DestructiveAlertModal';
import TableContentCell from '../../../components/system/table/TableContentCell';
import TableContentRow from '../../../components/system/table/TableContentRow';
import { UsersQuery } from './UsersTable.operations';
import { useDeleteOneUserMutation } from './UsersTableItem.operations';

type Props = {
  user: UsersQuery['users'][0];
};

function UsersTableItem({ user }: Props) {
  const [deleteOneUser, { loading }] = useDeleteOneUserMutation({
    variables: { where: { id: user.id } },
    refetchQueries: ['users'],
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DestructiveAlertModal
        isOpen={isDeleteModalOpen}
        title="Delete User"
        message={`Are you sure you want to delete ${user.first_name} ${user.last_name}?`}
        destructiveLabel="Delete"
        disabled={loading}
        onDestructiveClick={handleDeleteUser}
        onCancelClick={() => setDeleteModalOpen(false)}
      />
      <TableContentRow>
        <TableContentCell extraClassNames="font-medium text-sm text-gray-600">{user.id}</TableContentCell>
        <TableContentCell>{user.first_name}</TableContentCell>
        <TableContentCell>{user.last_name}</TableContentCell>
        <TableContentCell>{user.username}</TableContentCell>
        <TableContentCell>
          <span className={`text-label text-xs bg-gray-100 text-gray-700 capitalize`}>{user.role?.name}</span>
        </TableContentCell>
        <TableContentCell extraClassNames="text-sm text-gray-600">
          <YesNoLabel status={user.is_active} />
        </TableContentCell>
        <TableContentCell extraClassNames="flex flex-row justify-end font-medium text-sm text-gray-600 text-right">
          <Link
            className="flex items-center font-medium text-sm transition duration-200 ease-in-out text-gray-600 hover:text-gray-900"
            to={`/users/${user.id}`}
          >
            <FiEdit2 className="mr-1" />
            Edit
          </Link>
          <button
            className="flex items-center font-medium text-sm transition duration-200 ease-in-out text-red-600 hover:text-red-700 ml-5"
            onClick={() => setDeleteModalOpen(true)}
          >
            <FiTrash className="mr-1" />
            Delete
          </button>
        </TableContentCell>
      </TableContentRow>
    </>
  );

  async function handleDeleteUser() {
    try {
      await deleteOneUser();
    } finally {
      setDeleteModalOpen(true);
    }
  }
}

gql`
  mutation deleteOneUser($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
      first_name
      last_name
      username
      role {
        name
      }
      is_active
    }
  }
`;

export default UsersTableItem;
