import React from 'react';

import TableHeaderCell, { OrderedBy } from './TableHeaderCell';

export type ColumnOrderedByType = OrderedBy;

type Column = {
  id: string;
  name?: string;
  style?: string;
};

type Props = {
  columns: Column[];
  orderedBy?: OrderedBy;
  onChangeOrder?: (newOrderingCriteria: ColumnOrderedByType) => void;
};

function TableHeader({ columns, orderedBy, onChangeOrder }: Props) {
  return (
    <thead>
      <tr>
        {columns.map(column => (
          <TableHeaderCell
            key={column.id}
            columnId={column.id}
            columnName={column.name}
            extraClassNames={column.style}
            currentOrder={orderedBy}
            onClick={onChangeOrder}
          />
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
