import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthService from '../services/AuthService';

type Props = React.ComponentProps<typeof Route>;

function PrivateRoute({ children, ...rest }: Props) {
  const isAuthenticated = !!AuthService.getToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
