import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UpdateEntrySocietyListOrderMutationVariables = {
  input: Types.UpdateEntrySocietyListOrderInput;
};


export type UpdateEntrySocietyListOrderMutation = { updateEntrySocietyListOrder: Pick<Types.UpdateEntrySocietyListOrder, 'success'> };

export type DeleteOneEntrySocietyListMutationVariables = {
  where: Types.EntrySocietyListWhereUniqueInput;
};


export type DeleteOneEntrySocietyListMutation = { deleteOneEntrySocietyList?: Types.Maybe<Pick<Types.EntrySocietyList, 'id' | 'title' | 'entries_count'>> };

export type EntrySocietyListsQueryVariables = {
  where?: Types.Maybe<Types.EntrySocietyListWhereInput>;
  orderBy?: Types.Maybe<Types.EntrySocietyListOrderByInput>;
};


export type EntrySocietyListsQuery = { entrySocietyLists: Array<Pick<Types.EntrySocietyList, 'id' | 'title' | 'entries_count'>> };


export const UpdateEntrySocietyListOrderDocument = gql`
    mutation updateEntrySocietyListOrder($input: UpdateEntrySocietyListOrderInput!) {
  updateEntrySocietyListOrder(input: $input) {
    success
  }
}
    `;
export type UpdateEntrySocietyListOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateEntrySocietyListOrderMutation, UpdateEntrySocietyListOrderMutationVariables>;
export function useUpdateEntrySocietyListOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntrySocietyListOrderMutation, UpdateEntrySocietyListOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntrySocietyListOrderMutation, UpdateEntrySocietyListOrderMutationVariables>(UpdateEntrySocietyListOrderDocument, baseOptions);
      }
export type UpdateEntrySocietyListOrderMutationHookResult = ReturnType<typeof useUpdateEntrySocietyListOrderMutation>;
export type UpdateEntrySocietyListOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEntrySocietyListOrderMutation>;
export type UpdateEntrySocietyListOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntrySocietyListOrderMutation, UpdateEntrySocietyListOrderMutationVariables>;
export const DeleteOneEntrySocietyListDocument = gql`
    mutation deleteOneEntrySocietyList($where: EntrySocietyListWhereUniqueInput!) {
  deleteOneEntrySocietyList(where: $where) {
    id
    title
    entries_count
  }
}
    `;
export type DeleteOneEntrySocietyListMutationFn = ApolloReactCommon.MutationFunction<DeleteOneEntrySocietyListMutation, DeleteOneEntrySocietyListMutationVariables>;
export function useDeleteOneEntrySocietyListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneEntrySocietyListMutation, DeleteOneEntrySocietyListMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneEntrySocietyListMutation, DeleteOneEntrySocietyListMutationVariables>(DeleteOneEntrySocietyListDocument, baseOptions);
      }
export type DeleteOneEntrySocietyListMutationHookResult = ReturnType<typeof useDeleteOneEntrySocietyListMutation>;
export type DeleteOneEntrySocietyListMutationResult = ApolloReactCommon.MutationResult<DeleteOneEntrySocietyListMutation>;
export type DeleteOneEntrySocietyListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneEntrySocietyListMutation, DeleteOneEntrySocietyListMutationVariables>;
export const EntrySocietyListsDocument = gql`
    query entrySocietyLists($where: EntrySocietyListWhereInput, $orderBy: EntrySocietyListOrderByInput) {
  entrySocietyLists(where: $where, orderBy: $orderBy) {
    id
    title
    entries_count
  }
}
    `;
export function useEntrySocietyListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyListsQuery, EntrySocietyListsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyListsQuery, EntrySocietyListsQueryVariables>(EntrySocietyListsDocument, baseOptions);
      }
export function useEntrySocietyListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyListsQuery, EntrySocietyListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyListsQuery, EntrySocietyListsQueryVariables>(EntrySocietyListsDocument, baseOptions);
        }
export type EntrySocietyListsQueryHookResult = ReturnType<typeof useEntrySocietyListsQuery>;
export type EntrySocietyListsLazyQueryHookResult = ReturnType<typeof useEntrySocietyListsLazyQuery>;
export type EntrySocietyListsQueryResult = ApolloReactCommon.QueryResult<EntrySocietyListsQuery, EntrySocietyListsQueryVariables>;