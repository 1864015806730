import React from 'react';

import SEO from '../../components/system/seo';
import BasicTitlePage from '../../layout/BasicTitlePage';
import MenuPage from '../../layout/MenuPage';
import SettingsForm from './SettingsForm';

function SettingsScreen() {
  const title = 'Settings';

  return (
    <>
      <SEO title="Settings" />

      <MenuPage>
        <BasicTitlePage title={title}>
          <SettingsForm />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default SettingsScreen;
