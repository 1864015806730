import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

export type BreadcrumbItemType = { to: string; label: string };
type Props = {
  items?: BreadcrumbItemType[];
};

function Breadcrumb({ items }: Props) {
  if (!items) {
    return null;
  }

  const [firstItem, ...remainingItems] = items;

  return (
    <ul className="flex flex-row items-center text-sm transition duration-200 ease-in-out text-gray-700 hover:text-gray-900">
      <li>
        <NavLink exact to={firstItem.to} title={firstItem.label}>
          {firstItem.label}
        </NavLink>
      </li>

      {remainingItems.map((item) => (
        <>
          <li>
            <FiChevronRight className="text-gray-500 mx-1" />
          </li>
          <li>
            <NavLink exact to={item.to} title={item.label} activeClassName="text-gray-500 pointer-events-none">
              {item.label}
            </NavLink>
          </li>
        </>
      ))}
    </ul>
  );
}

export default Breadcrumb;
