type Item = { id: string | number };
type ItemDiff = {id: string, order_by: number};

export default function getOrderDiff(items: Item[], updatedItems: Item[]) {
  const diff: ItemDiff[] = [];

  updatedItems.forEach((item, index) => {
    if (item.id !== items[index].id) {
      diff.push({ id: String(item.id), order_by: index + 1 });
    }
  })

  return diff;
}
