import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EntryListsScreen from '../screens/entry-lists/list';
import AddEntryListScreen from '../screens/entry-lists/new';
import EditEntryListScreen from '../screens/entry-lists/edit';

function ListsRouter() {
  return (
    <Switch>
      <Route exact path="/lists">
        <EntryListsScreen />
      </Route>

      <Route exact path="/lists/new">
        <AddEntryListScreen />
      </Route>

      <Route exact path="/lists/:entryListId">
        <EditEntryListScreen />
      </Route>
    </Switch>
  );
}

export default ListsRouter;
