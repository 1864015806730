import React from 'react';
import { FiMenu } from 'react-icons/fi';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

function CollapseButton({ isActive, onClick }: Props) {
  return (
    <button
      className={`lg:hidden px-2 rounded-md text-lg ${isActive ? 'text-white bg-gray-900' : 'text-gray-600'}`}
      onClick={onClick}
    >
      <FiMenu />
    </button>
  );
}

export default CollapseButton;
