import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import EntryListForm from '../../../components/system/forms/EntryListForm';
import { useEntrySocietyListQuery, useUpdateOneEntrySocietyListMutation } from './EditEntryListForm.operations';

type Props = {
  entryListId: string;
};

function EditEntryListForm(props: Props) {
  const { data, loading, error } = useEntrySocietyListQuery({
    variables: { where: { id: parseInt(props.entryListId) } },
  });

  const [editEntrySocietyList] = useUpdateOneEntrySocietyListMutation({ refetchQueries: ['entrySocietyLists'], awaitRefetchQueries: true });

  const initialValues = {
    title: data?.entrySocietyList?.title || '',
  };

  async function handleSubmit(values: typeof initialValues) {
    try {
      await editEntrySocietyList({
        variables: {
          data: { title: values.title },
          where: { id: Number(props.entryListId) }
        },
      });

      toast.success(`${values.title} has been updated!`);
    } catch {
      toast.error('Oops, something went wrong.');
    }
  }

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the list. Please, try to refresh this page."
          containerClassName="mb-8"
        />
      )}

      <EntryListForm
        initialValues={initialValues}
        isDisabled={loading || !!error}
        onSubmit={handleSubmit}
      />
    </>
  );
}

gql`
  query entrySocietyList($where: EntrySocietyListWhereUniqueInput!) {
    entrySocietyList(where: $where) {
      ...ReturnEntrySocietyList
    }
  }

  mutation updateOneEntrySocietyList($data: EntrySocietyListUpdateInput!, $where: EntrySocietyListWhereUniqueInput!) {
    updateOneEntrySocietyList(data: $data, where: $where) {
      ...ReturnEntrySocietyList
    }
  }

  fragment ReturnEntrySocietyList on EntrySocietyList {
    id
    title
  }
`;

export default EditEntryListForm;
