import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

type Props = React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>;

const DropdownTrigger = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, className, onClick, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className={`flex items-center justify-center focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150 ${className}`}
        {...props}
      >
        {children}

        <FiChevronDown className="ml-1" />
      </button>
    );
  },
);

export default DropdownTrigger;
