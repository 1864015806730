import React from 'react';
import { StringValueNode } from 'graphql';

import Loader from '../loading-spinner';

type Props = {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingIconColor?: StringValueNode;
  onClick?: () => void;
}

function LoadingButton(props: React.PropsWithChildren<Props>) {
  const type = props.type || 'submit';

  return (
    <button 
      type={type} 
      className={`flex items-center justify-center ${props.className}`} 
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
    >
      {props.loading ? <Loader color="white" /> : props.children}
    </button>
  )
}

export default LoadingButton;