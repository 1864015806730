import React from 'react';

type Props = {
  status: boolean;
};

function YesNoLabel({ status }: Props) {
  return status ? (
    <span className={`text-label text-xs bg-green-100 text-green-700`}>Yes</span>
  ) : (
    <span className={`text-label text-xs bg-gray-100 text-gray-700`}>No</span>
  );
}

export default YesNoLabel;
