import React from 'react';

type Props = {
  name: string;
  checked?: boolean | null;
  label: string;
  error?: string | null;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'checked'>;

function TextInput(props: Props) {
  const { checked, label, onChange, error, name, ...inputProps } = props;

  return (
    <div className="flex flex-1 flex-col items-start">
      <div className="flex flex-1 w-full">
        <input
          type="checkbox"
          id={name}
          checked={!!checked}
          onChange={(event) => {
            if (!!onChange) {
              event.persist();
              onChange(event);
            }
          }}
          {...inputProps}
        />

        <label className="flex-1 font-medium text-sm text-gray-600 ml-2" htmlFor={name}>
          {label}
        </label>
      </div>

      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}
export default TextInput;
