import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import EntryListForm from '../../../components/system/forms/EntryListForm';
import { useCreateOneEntryListMutation } from './AddListForm.operations';

type Props = {
  afterSubmit: () => void;
};

function AddListForm(props: Props) {
  const [createEntryList] = useCreateOneEntryListMutation({ refetchQueries: ['entryLists'], awaitRefetchQueries: true });
  const initialValues = { title: '' };

  return (
    <>
      <EntryListForm
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await createEntryList({ variables: { input: { title: values.title } } });
            toast.success(`${values.title} has been created!`);

            props.afterSubmit();
          } catch {
            toast.error('Oops, something went wrong.');
          }
        }}
      />
    </>
  );
}

gql`
  mutation createOneEntryList($input: CreateEntryListInput!) {
    createOneEntryList(input: $input) {
      entryList {
        id
        title
      }
    }
  }
`;

export default AddListForm;
