import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import UserForm from '../../../components/system/forms/UserForm';
import { useCreateUserMutation, useRolesQuery } from './AddUserForm.operations';

type Props = {
  afterSubmit: () => void;
};

function AddUserForm(props: Props) {
  const { data: rolesData, loading: isRolesLoading } = useRolesQuery();
  const [createUser] = useCreateUserMutation({ refetchQueries: ['users'], awaitRefetchQueries: true });
  const initialValues = {
    first_name: '',
    last_name: '',
    username: '',
    password: '',
    role: '',
    is_active: false,
  };
  const roles = rolesData?.roles || [];

  return (
    <>
      <UserForm
        initialValues={initialValues}
        roles={roles}
        isRolesLoading={isRolesLoading}
        validationSchema={yup.object().shape({
          first_name: yup.string().required('First name is required'),
          last_name: yup.string().required('Last name is required'),
          username: yup.string().email('Invalid email format').required('Username is required'),
          password: yup.string().required('Password is required'),
          role: yup.string().required('Role is required'),
          is_active: yup.boolean(),
        })}
        onSubmit={async (values, actions) => {
          try {
            await createUser({
              variables: {
                input: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  username: values.username,
                  password: values.password,
                  is_active: values.is_active,
                  role: values.role,
                },
              },
            });

            toast.success(`${values.first_name}'s account has been created!`);

            props.afterSubmit();
          } catch {
            toast.error('Oops, something went wrong.');
          }
        }}
      />
    </>
  );
}

gql`
  query roles {
    roles {
      name
    }
  }

  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        first_name
        last_name
        username
        role {
          name
        }
        is_active
      }
    }
  }
`;

export default AddUserForm;
