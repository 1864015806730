import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SEO from '../../../components/system/seo';
import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import EditUserForm from './EditUserForm';

function EditUserScreen() {
  const { userId } = useParams();
  const history = useHistory();

  if (!userId) {
    history.replace('/users');

    return null;
  }

  const title = 'Edit User';
  const breadcrumbs = [
    { to: '/users/', label: 'Users' },
    { to: `/users/${userId}`, label: 'Edit' },
  ];

  return (
    <>
      <SEO title="Edit User" />

      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <EditUserForm userId={userId} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default EditUserScreen;
