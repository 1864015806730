import React from 'react';
import { AiFillBulb as HintIcon } from 'react-icons/ai';

type Props = {
  title?: string;
  description: string;
  containerClassName?: string;
};

function AlertInfo({ title, description, containerClassName }: Props) {
  return (
    <div className={`bg-gray-100 p-4 flex flex-row rounded-lg ${containerClassName}`}>
      <HintIcon size="24" className="text-gray-600 mr-2" />

      <div>
        {title && <p className="text-gray-700 text-sm font-medium mb-1">{title}</p>}
        <p className="text-gray-700 text-sm font-light">{description}</p>
      </div>
    </div>
  );
}

export default AlertInfo;
