import React from 'react';
import { NavLink } from 'react-router-dom';

import PageHeader from '../../../components/system/page-header';
import SEO from '../../../components/system/seo';
import MenuPage from '../../../layout/MenuPage';
import EntriesTable from './EntriesTable';

function EntriesScreen() {
  return (
    <>
      <SEO title="Entries" />

      <MenuPage>
        <div className="flex flex-1 flex-col p-4 sm:p-8">
          <PageHeader title="Entries">
            <NavLink to="/entries/new" className="btn btn-primary ml-auto">
              Add Entry
            </NavLink>
          </PageHeader>

          <EntriesTable />
        </div>
      </MenuPage>
    </>
  );
}

export default EntriesScreen;
