import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FiEdit2, FiTrash, FiMove, FiLock } from 'react-icons/fi';

import CountLabel from '../label/CountLabel';

type Props = {
  id: string;
  index: number;
  title: string;
  description?: string;
  count?: number;
  className?: string;
  isDragDisabled?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
};

function DraggableItem(props: React.PropsWithChildren<Props>) {
  return (
    <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style || {})}
          className={
            props.className ||
            'flex flex-row items-center space-x-3 bg-white rounded border border-gray-200 transition-shadow select-none p-4 mb-2'
          }
        >
          {!props.isDragDisabled ? <FiMove className="text-gray-400" /> : <FiLock className="text-gray-400" />}

          <div className="flex flex-1 flex-row items-center justify-between">
            <div>
              <p>{props.title}</p>
              {props.description && <p className="text-xs text-gray-600">{props.description}</p>}
            </div>

            <div className="flex flex-row">
              {props.count && (
                <div className="my-auto mr-4">
                  <CountLabel amount={props.count} />
                </div>
              )}

              {props.onEdit && (
                <button
                  className="flex items-center font-medium text-sm transition duration-200 ease-in-out text-gray-600 py-2 hover:text-gray-900"
                  onClick={() => props.onEdit?.(props.id)}
                >
                  <FiEdit2 className="mr-1" />
                  Edit
                </button>
              )}

              {props.onDelete && (
                <button
                  className="flex items-center font-medium text-sm transition duration-200 ease-in-out text-red-600 py-2 hover:text-red-700 ml-5"
                  onClick={() => props.onDelete?.(props.id)}
                >
                  <FiTrash className="mr-1" />
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

function getItemStyle(isDragging: boolean, draggableStyle: object) {
  return {
    boxShadow: isDragging ? '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)' : 'none',
    ...draggableStyle,
  };
}

export default DraggableItem;
