import React, { ReactNode } from 'react';

import MenuBar from './MenuBar';

type Props = {
  children?: ReactNode | ReactNode[] | null;
};

function MenuPage(props: Props) {
  return (
    <div className="min-h-screen bg-gray-200 flex flex-1 flex-col lg:flex-row">
      <MenuBar />

      {props.children}
    </div>
  );
}

export default MenuPage;
