import React from 'react';

import logo from '../../../assets/images/logo-dark.png';

function CompanyCard(props: React.PropsWithChildren<{}>) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 pt-4">
      <img src={logo} alt="Bok Towers" className="h-8 mt-4" />

      {props.children}
    </div>
  );
}

export default CompanyCard;
