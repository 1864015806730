import { FormikHelpers } from 'formik';
import React from 'react';

import useForm from '../../../hooks/useForm';
import { Role } from '../../../types/graphql';
import CheckboxInput from '../inputs/CheckboxInput';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';

export type UserFormValues = {
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  is_active: boolean;
  role: string;
};

type Props = {
  initialValues: UserFormValues;
  roles: Pick<Role, 'name'>[];
  isDisabled?: boolean;
  isRolesLoading: boolean;
  onSubmit: (values: UserFormValues, formikHelpers: FormikHelpers<UserFormValues>) => void | Promise<any>;
  validationSchema?: any | (() => any);
};

function UserForm(props: Props) {
  const form = useForm<UserFormValues>({
    enableReinitialize: true,
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    validationSchema: props.validationSchema,
  });

  const isDisabled = props.isDisabled || form.isSubmitting;

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <div className="grid grid-cols-1 gap-5">
          <TextInput
            value={form.values.first_name}
            name="first_name"
            onChange={form.handleChange}
            label="First Name"
            disabled={isDisabled}
            error={!!form.touched.first_name ? form.errors.first_name : null}
          />
          <TextInput
            value={form.values.last_name}
            name="last_name"
            onChange={form.handleChange}
            label="Last Name"
            disabled={isDisabled}
            error={!!form.touched.last_name ? form.errors.last_name : null}
          />
          <TextInput
            value={form.values.username}
            name="username"
            onChange={form.handleChange}
            label="Username (Email Address)"
            disabled={isDisabled}
            error={!!form.touched.username ? form.errors.username : null}
          />
          <TextInput
            value={form.values.password}
            name="password"
            onChange={form.handleChange}
            label="Password"
            disabled={isDisabled}
            error={!!form.touched.password ? form.errors.password : null}
            type="password"
          />
          <SelectInput
            value={!!form.values.role ? { value: form.values.role, label: form.values.role } : null}
            name="role"
            label="Role"
            options={props.roles.map((role) => ({
              value: role.name,
              label: role.name,
            }))}
            isLoading={props.isRolesLoading}
            isDisabled={props.isRolesLoading || isDisabled}
            onChange={(value) => form.setFieldValue('role', value)}
            error={!!form.touched.role ? form.errors.role : null}
          />
          <CheckboxInput
            name="is_active"
            onChange={({ target }) => form.setFieldValue('is_active', target.checked)}
            label="Is Active"
            disabled={isDisabled}
            checked={form.values.is_active}
            error={!!form.touched.is_active ? form.errors.is_active : null}
          />
        </div>
        <div className="flex justify-between mt-6 pt-6 border-t-2 border-gray-200">
          <input value="Save" type="submit" className="btn btn-primary px-6 ml-auto" disabled={isDisabled} />
        </div>
      </form>
    </>
  );
}

export default UserForm;
