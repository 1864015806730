import React, { useState } from 'react';
import { FiHexagon, FiLayers, FiSettings, FiUser, FiLogOut } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo-light.png';
import CollapseButton from './CollapseButton';
import MenuNavLink from './MenuNavLink';
import MenuNavLinkWithOptions from './MenuNavLinkWithOptions';

function MenuBar() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className="flex-shrink-0 bg-gray-800 px-3 lg:w-64 print:hidden">
      <div className="my-2 lg:my-4 flex flex-1 flex-row justify-between p-3">
        <NavLink exact to="/" title="Home">
          <img src={logo} alt="Bok Towers" className="h-8" />
        </NavLink>
        <CollapseButton isActive={!isCollapsed} onClick={toggleCollapse} />
      </div>

      <div className={`${isCollapsed ? 'hidden' : 'block'} lg:block pb-5 lg:pb-0`}>
        <div className="grid grid-cols-1 gap-2">
          <MenuNavLinkWithOptions title="Entries" Icon={FiHexagon} options={entryOptions} />
          <MenuNavLinkWithOptions title="Lists" Icon={FiLayers} options={entryListOptions} />

          <MenuNavLink to="/users" title={'Users'} Icon={FiUser} />
          <MenuNavLink to="/settings" title={'Settings'} Icon={FiSettings} />
        </div>
        <hr className="mt-4 mb-4 border-gray-700" />
        <div className="grid grid-cols-1 gap-2">
          <MenuNavLink to="/sign-out" title={'Sign Out'} Icon={FiLogOut} />
        </div>
      </div>
    </div>
  );
}

const entryOptions = [
  { title: 'Donor', to: '/entries' },
  { title: 'Society', to: '/entries-society' }
];

const entryListOptions = [
  { title: 'Donor', to: '/lists' },
  { title: 'Society', to: '/lists-society' }
];

export default MenuBar;
