import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type CreateOneEntryListMutationVariables = {
  input: Types.CreateEntryListInput;
};


export type CreateOneEntryListMutation = { createOneEntryList: { entryList: Pick<Types.EntryList, 'id' | 'title'> } };


export const CreateOneEntryListDocument = gql`
    mutation createOneEntryList($input: CreateEntryListInput!) {
  createOneEntryList(input: $input) {
    entryList {
      id
      title
    }
  }
}
    `;
export type CreateOneEntryListMutationFn = ApolloReactCommon.MutationFunction<CreateOneEntryListMutation, CreateOneEntryListMutationVariables>;
export function useCreateOneEntryListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneEntryListMutation, CreateOneEntryListMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneEntryListMutation, CreateOneEntryListMutationVariables>(CreateOneEntryListDocument, baseOptions);
      }
export type CreateOneEntryListMutationHookResult = ReturnType<typeof useCreateOneEntryListMutation>;
export type CreateOneEntryListMutationResult = ApolloReactCommon.MutationResult<CreateOneEntryListMutation>;
export type CreateOneEntryListMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneEntryListMutation, CreateOneEntryListMutationVariables>;