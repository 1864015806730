import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SEO from '../../../components/system/seo';
import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import EditEntryForm from './EditEntryForm';

function EditEntryScreen() {
  const { entryId } = useParams<{ entryId: string }>();
  const history = useHistory();

  if (!entryId) {
    history.replace('/entries');

    return null;
  }

  const title = 'Edit Entry';
  const breadcrumbs = [
    { to: '/entries/', label: 'Entries' },
    { to: `/entries/${entryId}`, label: 'Edit' },
  ];

  return (
    <>
      <SEO title={title} />

      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <EditEntryForm entryId={entryId} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default EditEntryScreen;
