import { ApolloError } from 'apollo-client';
import React, { useEffect } from 'react';
import * as yup from 'yup';

import LoadingButton from '../../components/system/buttons/LoadingButton';
import useForm from '../../hooks/useForm';
import { getMutationFieldErrors } from '../../utils/graphql';

export type SignInFormValues = { username: string; password: string };

type Props = {
  loading?: boolean;
  error?: ApolloError;
  onSubmit: (values: SignInFormValues) => void;
};

function SignInForm({ loading, error, onSubmit }: Props) {
  const initialValues = { username: '', password: '' };
  const validationSchema = yup.object().shape({
    username: yup.string().email('Invalid e-mail format').required('Email Address is required'),
    password: yup.string().required('Password is required'),
  });

  const form = useForm({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    const graphQLErrors = error?.graphQLErrors;

    if (graphQLErrors) {
      form.setErrors(getMutationFieldErrors(graphQLErrors));
    }
  }, [error]);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className="mb-5">
        <label htmlFor="username" className="block text-sm text-gray-600 font-medium mb-2">
          Email Address
        </label>
        <input
          id="username"
          className="field-input w-full"
          type="text"
          value={form.values.username}
          onChange={form.handleChange}
        />
        {!!form.errors.username && !!form.touched.username && (
          <span className="text-xs text-red-600">{form.errors.username}</span>
        )}
      </div>
      <div className="mb-5">
        <label htmlFor="password" className="block text-sm text-gray-600 font-medium mb-2">
          Password
        </label>
        <input
          id="password"
          className="field-input w-full"
          type="password"
          value={form.values.password}
          onChange={form.handleChange}
        />
        {!!form.errors.password && !!form.touched.password && (
          <span className="text-xs text-red-600">{form.errors.password}</span>
        )}
      </div>
      <div className="flex">
        <LoadingButton loading={loading} className="btn btn-primary w-full text-base py-3">
          Sign In
        </LoadingButton>
      </div>
    </form>
  );
}

export default SignInForm;
