import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useClickAway from '../../../../hooks/useClickAway';
import DropdownTrigger from './DropdownTrigger';

type Props = {
  className?: string;
  triggerTitle: string;
  triggerClassname?: string;
};

function DropdownButton(props: React.PropsWithChildren<Props>) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useClickAway(triggerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`relative inline-block text-left ${props.className}`}>
      <DropdownTrigger ref={triggerRef} onClick={toggleDropdown} className={props.triggerClassname}>
        {props.triggerTitle}
      </DropdownTrigger>

      <CSSTransition
        unmountOnExit
        in={isOpen}
        timeout={100}
        classNames={{
          enter: 'transition ease-out duration-100 opacity-0 scale-95',
          enterActive: 'opacity-100 scale-100',
          exit: 'transition ease-in duration-100 opacity-100',
          exitActive: 'transform opacity-0',
        }}
      >
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
          <div className="rounded-md bg-white shadow-xs py-1">{props.children}</div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropdownButton;
