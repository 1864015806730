import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntrySocietyListQueryVariables = {
  where: Types.EntrySocietyListWhereUniqueInput;
};


export type EntrySocietyListQuery = { entrySocietyList?: Types.Maybe<ReturnEntrySocietyListFragment> };

export type UpdateOneEntrySocietyListMutationVariables = {
  data: Types.EntrySocietyListUpdateInput;
  where: Types.EntrySocietyListWhereUniqueInput;
};


export type UpdateOneEntrySocietyListMutation = { updateOneEntrySocietyList?: Types.Maybe<ReturnEntrySocietyListFragment> };

export type ReturnEntrySocietyListFragment = Pick<Types.EntrySocietyList, 'id' | 'title'>;

export const ReturnEntrySocietyListFragmentDoc = gql`
    fragment ReturnEntrySocietyList on EntrySocietyList {
  id
  title
}
    `;
export const EntrySocietyListDocument = gql`
    query entrySocietyList($where: EntrySocietyListWhereUniqueInput!) {
  entrySocietyList(where: $where) {
    ...ReturnEntrySocietyList
  }
}
    ${ReturnEntrySocietyListFragmentDoc}`;
export function useEntrySocietyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyListQuery, EntrySocietyListQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyListQuery, EntrySocietyListQueryVariables>(EntrySocietyListDocument, baseOptions);
      }
export function useEntrySocietyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyListQuery, EntrySocietyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyListQuery, EntrySocietyListQueryVariables>(EntrySocietyListDocument, baseOptions);
        }
export type EntrySocietyListQueryHookResult = ReturnType<typeof useEntrySocietyListQuery>;
export type EntrySocietyListLazyQueryHookResult = ReturnType<typeof useEntrySocietyListLazyQuery>;
export type EntrySocietyListQueryResult = ApolloReactCommon.QueryResult<EntrySocietyListQuery, EntrySocietyListQueryVariables>;
export const UpdateOneEntrySocietyListDocument = gql`
    mutation updateOneEntrySocietyList($data: EntrySocietyListUpdateInput!, $where: EntrySocietyListWhereUniqueInput!) {
  updateOneEntrySocietyList(data: $data, where: $where) {
    ...ReturnEntrySocietyList
  }
}
    ${ReturnEntrySocietyListFragmentDoc}`;
export type UpdateOneEntrySocietyListMutationFn = ApolloReactCommon.MutationFunction<UpdateOneEntrySocietyListMutation, UpdateOneEntrySocietyListMutationVariables>;
export function useUpdateOneEntrySocietyListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneEntrySocietyListMutation, UpdateOneEntrySocietyListMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneEntrySocietyListMutation, UpdateOneEntrySocietyListMutationVariables>(UpdateOneEntrySocietyListDocument, baseOptions);
      }
export type UpdateOneEntrySocietyListMutationHookResult = ReturnType<typeof useUpdateOneEntrySocietyListMutation>;
export type UpdateOneEntrySocietyListMutationResult = ApolloReactCommon.MutationResult<UpdateOneEntrySocietyListMutation>;
export type UpdateOneEntrySocietyListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneEntrySocietyListMutation, UpdateOneEntrySocietyListMutationVariables>;