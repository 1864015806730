import React from 'react';

type Props = {
  empty?: boolean;
};

function EmptyPanel({ empty }: Props) {
  if (!empty) {
    return null;
  }

  return (
    <div className="flex flex-col align-center justify-center text-center h-56 px-10">
      <>
        <h2 className="text-xl font-medium text-gray-800 mb-1">No Results!</h2>
        <p className="text-gray-600">We could not find available results for this query.</p>
      </>
    </div>
  );
}

export default EmptyPanel;
