import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import AddListForm from './AddListForm';

function AddEntrySocietyListScreen() {
  const history = useHistory();

  const title = 'Add List';
  const breadcrumbs = [
    { to: '/lists-society/', label: 'Society Lists' },
    { to: '/lists-society/new', label: 'Add' },
  ];

  return (
    <>
      <Helmet>
        <title>Add List - HDI Admin System</title>
      </Helmet>
      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <AddListForm afterSubmit={() => history.push('/lists-society')} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default AddEntrySocietyListScreen;
