import React from 'react';
import ReactModal from 'react-modal';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  destructiveLabel: string;
  disabled: boolean;
  onDestructiveClick: () => void;
  onCancelClick: () => void;
};

function DestructiveAlertModal(props: Props) {
  return (
    <ReactModal
      isOpen={props.isOpen}
      className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full border border-gray-100 focus:outline-none mx-auto mt-20"
    >
      <div>
        <div className="m-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title}</h3>
          <div className="mt-2">
            <p className="text-sm leading-5 text-gray-500">{props.message}</p>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${props.disabled ? 'opacity-50' : ''}`}
              onClick={props.onDestructiveClick}
              disabled={props.disabled}
            >
              {props.destructiveLabel}
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={props.onCancelClick}
              disabled={props.disabled}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </ReactModal>
  );
}

export default DestructiveAlertModal;
