import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UpdateEntryOrderMutationVariables = {
  input: Types.UpdateEntryOrderInput;
};


export type UpdateEntryOrderMutation = { updateEntryOrder: Pick<Types.UpdateEntryOrder, 'success'> };

export type DeleteOneEntryMutationVariables = {
  where: Types.EntryWhereUniqueInput;
};


export type DeleteOneEntryMutation = { deleteOneEntry?: Types.Maybe<Pick<Types.Entry, 'id' | 'title'>> };

export type EntryListsOnListQueryVariables = {};


export type EntryListsOnListQuery = { entryLists: Array<Pick<Types.EntryList, 'id' | 'title'>> };

export type EntriesQueryVariables = {
  where?: Types.Maybe<Types.EntryWhereInput>;
  orderBy?: Types.Maybe<Types.EntryOrderByInput>;
};


export type EntriesQuery = { entries: Array<(
    Pick<Types.Entry, 'id' | 'title'>
    & { list: Pick<Types.EntryList, 'id' | 'title'> }
  )> };


export const UpdateEntryOrderDocument = gql`
    mutation updateEntryOrder($input: UpdateEntryOrderInput!) {
  updateEntryOrder(input: $input) {
    success
  }
}
    `;
export type UpdateEntryOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateEntryOrderMutation, UpdateEntryOrderMutationVariables>;
export function useUpdateEntryOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntryOrderMutation, UpdateEntryOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntryOrderMutation, UpdateEntryOrderMutationVariables>(UpdateEntryOrderDocument, baseOptions);
      }
export type UpdateEntryOrderMutationHookResult = ReturnType<typeof useUpdateEntryOrderMutation>;
export type UpdateEntryOrderMutationResult = ApolloReactCommon.MutationResult<UpdateEntryOrderMutation>;
export type UpdateEntryOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntryOrderMutation, UpdateEntryOrderMutationVariables>;
export const DeleteOneEntryDocument = gql`
    mutation deleteOneEntry($where: EntryWhereUniqueInput!) {
  deleteOneEntry(where: $where) {
    id
    title
  }
}
    `;
export type DeleteOneEntryMutationFn = ApolloReactCommon.MutationFunction<DeleteOneEntryMutation, DeleteOneEntryMutationVariables>;
export function useDeleteOneEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneEntryMutation, DeleteOneEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneEntryMutation, DeleteOneEntryMutationVariables>(DeleteOneEntryDocument, baseOptions);
      }
export type DeleteOneEntryMutationHookResult = ReturnType<typeof useDeleteOneEntryMutation>;
export type DeleteOneEntryMutationResult = ApolloReactCommon.MutationResult<DeleteOneEntryMutation>;
export type DeleteOneEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneEntryMutation, DeleteOneEntryMutationVariables>;
export const EntryListsOnListDocument = gql`
    query entryListsOnList {
  entryLists {
    id
    title
  }
}
    `;
export function useEntryListsOnListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryListsOnListQuery, EntryListsOnListQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryListsOnListQuery, EntryListsOnListQueryVariables>(EntryListsOnListDocument, baseOptions);
      }
export function useEntryListsOnListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryListsOnListQuery, EntryListsOnListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryListsOnListQuery, EntryListsOnListQueryVariables>(EntryListsOnListDocument, baseOptions);
        }
export type EntryListsOnListQueryHookResult = ReturnType<typeof useEntryListsOnListQuery>;
export type EntryListsOnListLazyQueryHookResult = ReturnType<typeof useEntryListsOnListLazyQuery>;
export type EntryListsOnListQueryResult = ApolloReactCommon.QueryResult<EntryListsOnListQuery, EntryListsOnListQueryVariables>;
export const EntriesDocument = gql`
    query entries($where: EntryWhereInput, $orderBy: EntryOrderByInput) {
  entries(where: $where, orderBy: $orderBy) {
    id
    title
    list {
      id
      title
    }
  }
}
    `;
export function useEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
      }
export function useEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
        }
export type EntriesQueryHookResult = ReturnType<typeof useEntriesQuery>;
export type EntriesLazyQueryHookResult = ReturnType<typeof useEntriesLazyQuery>;
export type EntriesQueryResult = ApolloReactCommon.QueryResult<EntriesQuery, EntriesQueryVariables>;