export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: any;
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type CreateEntry = {
   __typename?: 'CreateEntry';
  entry: Entry;
};

export type CreateEntryInput = {
  title: Scalars['String'];
  list_id: Scalars['ID'];
};

export type CreateEntryList = {
   __typename?: 'CreateEntryList';
  entryList: EntryList;
};

export type CreateEntryListInput = {
  title: Scalars['String'];
};

export type CreateEntrySociety = {
   __typename?: 'CreateEntrySociety';
  entrySociety: EntrySociety;
};

export type CreateEntrySocietyInput = {
  title: Scalars['String'];
  list_id: Scalars['ID'];
};

export type CreateEntrySocietyList = {
   __typename?: 'CreateEntrySocietyList';
  entrySocietyList: EntrySocietyList;
};

export type CreateEntrySocietyListInput = {
  title: Scalars['String'];
};

export type CreateUser = {
   __typename?: 'CreateUser';
  user: User;
};

export type CreateUserInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  is_active: Scalars['Boolean'];
  role: Scalars['String'];
};



export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  not?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
};

export type EditUser = {
   __typename?: 'EditUser';
  user: User;
};

export type EditUserInput = {
  id: Scalars['ID'];
  username: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  is_active: Scalars['Boolean'];
  role: Scalars['String'];
};

export type Entry = {
   __typename?: 'Entry';
  id: Scalars['Int'];
  title: Scalars['String'];
  order_by: Scalars['Int'];
  list: EntryList;
};

export type EntryCreateWithoutListInput = {
  title: Scalars['String'];
  order_by: Scalars['Int'];
};

export type EntryFilter = {
  every?: Maybe<EntryWhereInput>;
  some?: Maybe<EntryWhereInput>;
  none?: Maybe<EntryWhereInput>;
};

export type EntryList = {
   __typename?: 'EntryList';
  id: Scalars['Int'];
  title: Scalars['String'];
  entries_count: Scalars['Int'];
};

export type EntryListCreateWithoutEntriesInput = {
  title: Scalars['String'];
  order_by: Scalars['Int'];
};

export type EntryListOrderByInput = {
  id?: Maybe<OrderByArg>;
  title?: Maybe<OrderByArg>;
  order_by?: Maybe<OrderByArg>;
};

export type EntryListUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
  entries?: Maybe<EntryUpdateManyWithoutListInput>;
};

export type EntryListUpdateOneRequiredWithoutEntriesInput = {
  create?: Maybe<EntryListCreateWithoutEntriesInput>;
  connect?: Maybe<EntryListWhereUniqueInput>;
  update?: Maybe<EntryListUpdateWithoutEntriesDataInput>;
  upsert?: Maybe<EntryListUpsertWithoutEntriesInput>;
};

export type EntryListUpdateWithoutEntriesDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntryListUpsertWithoutEntriesInput = {
  update: EntryListUpdateWithoutEntriesDataInput;
  create: EntryListCreateWithoutEntriesInput;
};

export type EntryListWhereInput = {
  id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  entries?: Maybe<EntryFilter>;
  AND?: Maybe<Array<EntryListWhereInput>>;
  OR?: Maybe<Array<EntryListWhereInput>>;
  NOT?: Maybe<Array<EntryListWhereInput>>;
};

export type EntryListWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type EntryOrderByInput = {
  id?: Maybe<OrderByArg>;
  list_id?: Maybe<OrderByArg>;
  title?: Maybe<OrderByArg>;
  order_by?: Maybe<OrderByArg>;
};

export type EntryScalarWhereInput = {
  id?: Maybe<IntFilter>;
  list_id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  AND?: Maybe<Array<EntryScalarWhereInput>>;
  OR?: Maybe<Array<EntryScalarWhereInput>>;
  NOT?: Maybe<Array<EntryScalarWhereInput>>;
};

export type EntrySociety = {
   __typename?: 'EntrySociety';
  id: Scalars['Int'];
  title: Scalars['String'];
  order_by: Scalars['Int'];
  list: EntrySocietyList;
};

export type EntrySocietyCreateWithoutListInput = {
  title: Scalars['String'];
  order_by: Scalars['Int'];
};

export type EntrySocietyFilter = {
  every?: Maybe<EntrySocietyWhereInput>;
  some?: Maybe<EntrySocietyWhereInput>;
  none?: Maybe<EntrySocietyWhereInput>;
};

export type EntrySocietyList = {
   __typename?: 'EntrySocietyList';
  id: Scalars['Int'];
  title: Scalars['String'];
  entries_count: Scalars['Int'];
};

export type EntrySocietyListCreateWithoutEntriesInput = {
  title: Scalars['String'];
  order_by: Scalars['Int'];
};

export type EntrySocietyListOrderByInput = {
  id?: Maybe<OrderByArg>;
  title?: Maybe<OrderByArg>;
  order_by?: Maybe<OrderByArg>;
};

export type EntrySocietyListUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
  entries?: Maybe<EntrySocietyUpdateManyWithoutListInput>;
};

export type EntrySocietyListUpdateOneRequiredWithoutEntriesInput = {
  create?: Maybe<EntrySocietyListCreateWithoutEntriesInput>;
  connect?: Maybe<EntrySocietyListWhereUniqueInput>;
  update?: Maybe<EntrySocietyListUpdateWithoutEntriesDataInput>;
  upsert?: Maybe<EntrySocietyListUpsertWithoutEntriesInput>;
};

export type EntrySocietyListUpdateWithoutEntriesDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntrySocietyListUpsertWithoutEntriesInput = {
  update: EntrySocietyListUpdateWithoutEntriesDataInput;
  create: EntrySocietyListCreateWithoutEntriesInput;
};

export type EntrySocietyListWhereInput = {
  id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  entries?: Maybe<EntrySocietyFilter>;
  AND?: Maybe<Array<EntrySocietyListWhereInput>>;
  OR?: Maybe<Array<EntrySocietyListWhereInput>>;
  NOT?: Maybe<Array<EntrySocietyListWhereInput>>;
};

export type EntrySocietyListWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type EntrySocietyOrderByInput = {
  id?: Maybe<OrderByArg>;
  list_id?: Maybe<OrderByArg>;
  title?: Maybe<OrderByArg>;
  order_by?: Maybe<OrderByArg>;
};

export type EntrySocietyScalarWhereInput = {
  id?: Maybe<IntFilter>;
  list_id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  AND?: Maybe<Array<EntrySocietyScalarWhereInput>>;
  OR?: Maybe<Array<EntrySocietyScalarWhereInput>>;
  NOT?: Maybe<Array<EntrySocietyScalarWhereInput>>;
};

export type EntrySocietyUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
  list?: Maybe<EntrySocietyListUpdateOneRequiredWithoutEntriesInput>;
};

export type EntrySocietyUpdateManyDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntrySocietyUpdateManyWithoutListInput = {
  create?: Maybe<Array<EntrySocietyCreateWithoutListInput>>;
  connect?: Maybe<Array<EntrySocietyWhereUniqueInput>>;
  set?: Maybe<Array<EntrySocietyWhereUniqueInput>>;
  disconnect?: Maybe<Array<EntrySocietyWhereUniqueInput>>;
  delete?: Maybe<Array<EntrySocietyWhereUniqueInput>>;
  update?: Maybe<Array<EntrySocietyUpdateWithWhereUniqueWithoutListInput>>;
  updateMany?: Maybe<Array<EntrySocietyUpdateManyWithWhereNestedInput>>;
  deleteMany?: Maybe<Array<EntrySocietyScalarWhereInput>>;
  upsert?: Maybe<Array<EntrySocietyUpsertWithWhereUniqueWithoutListInput>>;
};

export type EntrySocietyUpdateManyWithWhereNestedInput = {
  where: EntrySocietyScalarWhereInput;
  data: EntrySocietyUpdateManyDataInput;
};

export type EntrySocietyUpdateWithoutListDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntrySocietyUpdateWithWhereUniqueWithoutListInput = {
  where: EntrySocietyWhereUniqueInput;
  data: EntrySocietyUpdateWithoutListDataInput;
};

export type EntrySocietyUpsertWithWhereUniqueWithoutListInput = {
  where: EntrySocietyWhereUniqueInput;
  update: EntrySocietyUpdateWithoutListDataInput;
  create: EntrySocietyCreateWithoutListInput;
};

export type EntrySocietyWhereInput = {
  id?: Maybe<IntFilter>;
  list_id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  AND?: Maybe<Array<EntrySocietyWhereInput>>;
  OR?: Maybe<Array<EntrySocietyWhereInput>>;
  NOT?: Maybe<Array<EntrySocietyWhereInput>>;
  list?: Maybe<EntrySocietyListWhereInput>;
};

export type EntrySocietyWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type EntryUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
  list?: Maybe<EntryListUpdateOneRequiredWithoutEntriesInput>;
};

export type EntryUpdateManyDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntryUpdateManyWithoutListInput = {
  create?: Maybe<Array<EntryCreateWithoutListInput>>;
  connect?: Maybe<Array<EntryWhereUniqueInput>>;
  set?: Maybe<Array<EntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<EntryWhereUniqueInput>>;
  delete?: Maybe<Array<EntryWhereUniqueInput>>;
  update?: Maybe<Array<EntryUpdateWithWhereUniqueWithoutListInput>>;
  updateMany?: Maybe<Array<EntryUpdateManyWithWhereNestedInput>>;
  deleteMany?: Maybe<Array<EntryScalarWhereInput>>;
  upsert?: Maybe<Array<EntryUpsertWithWhereUniqueWithoutListInput>>;
};

export type EntryUpdateManyWithWhereNestedInput = {
  where: EntryScalarWhereInput;
  data: EntryUpdateManyDataInput;
};

export type EntryUpdateWithoutListDataInput = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type EntryUpdateWithWhereUniqueWithoutListInput = {
  where: EntryWhereUniqueInput;
  data: EntryUpdateWithoutListDataInput;
};

export type EntryUpsertWithWhereUniqueWithoutListInput = {
  where: EntryWhereUniqueInput;
  update: EntryUpdateWithoutListDataInput;
  create: EntryCreateWithoutListInput;
};

export type EntryWhereInput = {
  id?: Maybe<IntFilter>;
  list_id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  order_by?: Maybe<IntFilter>;
  AND?: Maybe<Array<EntryWhereInput>>;
  OR?: Maybe<Array<EntryWhereInput>>;
  NOT?: Maybe<Array<EntryWhereInput>>;
  list?: Maybe<EntryListWhereInput>;
};

export type EntryWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  createOneEntry: CreateEntry;
  updateEntryOrder: UpdateEntryOrder;
  updateOneEntry?: Maybe<Entry>;
  deleteOneEntry?: Maybe<Entry>;
  createOneEntrySociety: CreateEntrySociety;
  updateEntrySocietyOrder: UpdateEntrySocietyOrder;
  updateOneEntrySociety?: Maybe<EntrySociety>;
  deleteOneEntrySociety?: Maybe<EntrySociety>;
  createOneEntryList: CreateEntryList;
  updateEntryListOrder: UpdateEntryListOrder;
  updateOneEntryList?: Maybe<EntryList>;
  deleteOneEntryList?: Maybe<EntryList>;
  createOneEntrySocietyList: CreateEntrySocietyList;
  updateEntrySocietyListOrder: UpdateEntrySocietyListOrder;
  updateOneEntrySocietyList?: Maybe<EntrySocietyList>;
  deleteOneEntrySocietyList?: Maybe<EntrySocietyList>;
  updateSettings?: Maybe<Setting>;
  createUser: CreateUser;
  editUser: EditUser;
  signIn: SignIn;
  deleteOneUser?: Maybe<User>;
  requestResetPassword: RequestResetPassword;
  resetPassword: ResetPassword;
};


export type MutationCreateOneEntryArgs = {
  input: CreateEntryInput;
};


export type MutationUpdateEntryOrderArgs = {
  input: UpdateEntryOrderInput;
};


export type MutationUpdateOneEntryArgs = {
  data: EntryUpdateInput;
  where: EntryWhereUniqueInput;
};


export type MutationDeleteOneEntryArgs = {
  where: EntryWhereUniqueInput;
};


export type MutationCreateOneEntrySocietyArgs = {
  input: CreateEntrySocietyInput;
};


export type MutationUpdateEntrySocietyOrderArgs = {
  input: UpdateEntrySocietyOrderInput;
};


export type MutationUpdateOneEntrySocietyArgs = {
  data: EntrySocietyUpdateInput;
  where: EntrySocietyWhereUniqueInput;
};


export type MutationDeleteOneEntrySocietyArgs = {
  where: EntrySocietyWhereUniqueInput;
};


export type MutationCreateOneEntryListArgs = {
  input: CreateEntryListInput;
};


export type MutationUpdateEntryListOrderArgs = {
  input: UpdateEntryListOrderInput;
};


export type MutationUpdateOneEntryListArgs = {
  data: EntryListUpdateInput;
  where: EntryListWhereUniqueInput;
};


export type MutationDeleteOneEntryListArgs = {
  where: EntryListWhereUniqueInput;
};


export type MutationCreateOneEntrySocietyListArgs = {
  input: CreateEntrySocietyListInput;
};


export type MutationUpdateEntrySocietyListOrderArgs = {
  input: UpdateEntrySocietyListOrderInput;
};


export type MutationUpdateOneEntrySocietyListArgs = {
  data: EntrySocietyListUpdateInput;
  where: EntrySocietyListWhereUniqueInput;
};


export type MutationDeleteOneEntrySocietyListArgs = {
  where: EntrySocietyListWhereUniqueInput;
};


export type MutationUpdateSettingsArgs = {
  data: SettingUpdateInput;
  where: SettingWhereUniqueInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type NullableIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};

export enum OrderByArg {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderDiffInput = {
  id?: Maybe<Scalars['ID']>;
  order_by?: Maybe<Scalars['Int']>;
};

export type Query = {
   __typename?: 'Query';
  entry?: Maybe<Entry>;
  entries: Array<Entry>;
  entrySociety?: Maybe<EntrySociety>;
  entrySocieties: Array<EntrySociety>;
  entryList?: Maybe<EntryList>;
  entryLists: Array<EntryList>;
  entrySocietyList?: Maybe<EntrySocietyList>;
  entrySocietyLists: Array<EntrySocietyList>;
  roles: Array<Role>;
  getSettings?: Maybe<Setting>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryEntryArgs = {
  where: EntryWhereUniqueInput;
};


export type QueryEntriesArgs = {
  where?: Maybe<EntryWhereInput>;
  orderBy?: Maybe<EntryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryEntrySocietyArgs = {
  where: EntrySocietyWhereUniqueInput;
};


export type QueryEntrySocietiesArgs = {
  where?: Maybe<EntrySocietyWhereInput>;
  orderBy?: Maybe<EntrySocietyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryEntryListArgs = {
  where: EntryListWhereUniqueInput;
};


export type QueryEntryListsArgs = {
  where?: Maybe<EntryListWhereInput>;
  orderBy?: Maybe<EntryListOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryEntrySocietyListArgs = {
  where: EntrySocietyListWhereUniqueInput;
};


export type QueryEntrySocietyListsArgs = {
  where?: Maybe<EntrySocietyListWhereInput>;
  orderBy?: Maybe<EntrySocietyListOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRolesArgs = {
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetSettingsArgs = {
  where: SettingWhereUniqueInput;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type RequestResetPassword = {
   __typename?: 'RequestResetPassword';
  status: Scalars['Boolean'];
};

export type RequestResetPasswordInput = {
  username: Scalars['String'];
};

export type ResetPassword = {
   __typename?: 'ResetPassword';
  user: User;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Role = {
   __typename?: 'Role';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type RoleWhereInput = {
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserFilter>;
  AND?: Maybe<Array<RoleWhereInput>>;
  OR?: Maybe<Array<RoleWhereInput>>;
  NOT?: Maybe<Array<RoleWhereInput>>;
};

export type Setting = {
   __typename?: 'Setting';
  id: Scalars['Int'];
  outgoing_email_address: Scalars['String'];
  incoming_email_address: Scalars['String'];
  session_timeout: Scalars['Int'];
  max_failed_attempts: Scalars['Int'];
  google_analytics_tracking_id: Scalars['String'];
};

export type SettingUpdateInput = {
  created_at?: Maybe<Scalars['DateTime']>;
  google_analytics_tracking_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  incoming_email_address?: Maybe<Scalars['String']>;
  max_failed_attempts?: Maybe<Scalars['Int']>;
  outgoing_email_address?: Maybe<Scalars['String']>;
  session_timeout?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SettingWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type SignIn = {
   __typename?: 'SignIn';
  user: User;
  token: Scalars['String'];
};

export type SignInInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
};

export type UpdateEntryListOrder = {
   __typename?: 'UpdateEntryListOrder';
  success: Scalars['Boolean'];
};

export type UpdateEntryListOrderInput = {
  data: Array<OrderDiffInput>;
};

export type UpdateEntryOrder = {
   __typename?: 'UpdateEntryOrder';
  success: Scalars['Boolean'];
};

export type UpdateEntryOrderInput = {
  data: Array<OrderDiffInput>;
};

export type UpdateEntrySocietyListOrder = {
   __typename?: 'UpdateEntrySocietyListOrder';
  success: Scalars['Boolean'];
};

export type UpdateEntrySocietyListOrderInput = {
  data: Array<OrderDiffInput>;
};

export type UpdateEntrySocietyOrder = {
   __typename?: 'UpdateEntrySocietyOrder';
  success: Scalars['Boolean'];
};

export type UpdateEntrySocietyOrderInput = {
  data: Array<OrderDiffInput>;
};

export type User = {
   __typename?: 'User';
  id: Scalars['Int'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  role?: Maybe<Role>;
  is_active: Scalars['Boolean'];
};

export type UserFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserOrderByInput = {
  created_at?: Maybe<OrderByArg>;
  failed_sign_in_attempts?: Maybe<OrderByArg>;
  first_name?: Maybe<OrderByArg>;
  id?: Maybe<OrderByArg>;
  is_active?: Maybe<OrderByArg>;
  last_name?: Maybe<OrderByArg>;
  password?: Maybe<OrderByArg>;
  role_id?: Maybe<OrderByArg>;
  updated_at?: Maybe<OrderByArg>;
  username?: Maybe<OrderByArg>;
};

export type UserWhereInput = {
  created_at?: Maybe<DateTimeFilter>;
  failed_sign_in_attempts?: Maybe<IntFilter>;
  first_name?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_active?: Maybe<BooleanFilter>;
  last_name?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  role_id?: Maybe<NullableIntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  role?: Maybe<RoleWhereInput>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  role_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};
