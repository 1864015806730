const LOCAL_STORAGE_TOKEN_KEY = 'hdi-admin-system-token-key';

function setToken(token: string) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

function getToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

function removeToken() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}

export default { setToken, getToken, removeToken };
