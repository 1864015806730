import exportFromJSON from 'export-from-json';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import ExportDropdownButton from '../../../components/system/buttons/custom/ExportDropdownButton';
import PrintButton from '../../../components/system/buttons/custom/PrintButton';
import FilterButton from '../../../components/system/buttons/FilterButton';
import SearchFilter from '../../../components/system/filters/custom/SearchFilter';
import EmptyPanel from '../../../components/system/panel/EmptyPanel';
import TableFiltersActionsContainer from '../../../components/system/table/TableFiltersActionsContainer';
import TableFiltersContainer from '../../../components/system/table/TableFiltersContainer';
import TableFiltersForm from '../../../components/system/table/TableFiltersForm';
import { ColumnOrderedByType } from '../../../components/system/table/TableHeader';
import { EntryWhereInput, OrderByArg } from '../../../types/graphql';
import { EntriesQuery, useEntriesQuery, useEntryListsOnListQuery, useDeleteOneEntryMutation, useUpdateEntryOrderMutation } from './EntriesTable.operations';
import DraggableItem from '../../../components/system/elements/DraggableItem';
import DroppableArea from '../../../components/system/elements/DroppableArea';
import DroppableContainer from '../../../components/system/elements/DroppableContainer';
import { useHistory } from 'react-router-dom';
import DestructiveAlertModal from '../../../components/system/modals/DestructiveAlertModal';
import SelectFilter, { SelectFilterOption } from '../../../components/system/filters/SelectFilter';
import AlertInfo from '../../../components/system/alerts/AlertInfo';
import getOrderDiff from '../../../utils/helpers/getOrderDiff';

type Entries = EntriesQuery['entries'];

const DEFAULT_ORDER: ColumnOrderedByType = { order_by: OrderByArg['Asc'] };

function EntriesTable() {
  const { data, loading, error, refetch } = useEntriesQuery({ variables: { orderBy: DEFAULT_ORDER } });
  const { data: listsData } = useEntryListsOnListQuery();
  const [deleteEntry, { loading: removing }] = useDeleteOneEntryMutation({ refetchQueries: ['entries'] });
  const [updateEntriesOrder] = useUpdateEntryOrderMutation();

  const [deletableItemId, setDeletableItemId] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<Entries>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [listIdFilter, setListIdFilter] = useState('');
  const [activeListIdFilter, setActiveListIdFilter] = useState('');
  const history = useHistory();

  const entries = data?.entries;
  const hasNoListFilters = !activeListIdFilter;
  const hasNoResults = !loading && !!items && items.length <= 0;

  useEffect(() => {
    if (entries) {
      setItems(entries);
    }
  }, [entries]);

  async function buildExportablePayload() {
    const data: {}[] = [];
    const fileName = 'entries';

    items.forEach((entries) => {
      data.push({
        ID: entries.id,
        'Title': entries.title,
      });
    });

    return { data, fileName };
  }

  function handleFilterChange(filterId: string, value: any) {
    if (filterId === 'search') {
      setSearchTerm(value);
    } else if (filterId === 'list_id') {
      setListIdFilter(value.target.value);
    }
  }

  function handleFilter(event?: React.FormEvent) {
    event?.preventDefault();

    if (!searchTerm && !listIdFilter) {
      setActiveListIdFilter('');
      return refetch({ where: {} }).catch(() => {});
    }

    const searchTermAsInt = parseInt(searchTerm, 10);
    const conditions: EntryWhereInput[] = [];

    if (searchTerm) {
      conditions.push({ title: { contains: searchTerm } });
    }

    if (searchTerm && searchTermAsInt) {
      conditions.push({ id: { equals: searchTermAsInt } });
    }

    if (listIdFilter) {
      conditions.push({ list: { id: { equals: Number(listIdFilter) } } })
    }

    refetch({ where: { OR: conditions } });
    setActiveListIdFilter(listIdFilter);
  }

  function handleOrderChanged(updatedItems: Entries) {
    setItems(updatedItems);

    const entries = getOrderDiff(items, updatedItems);
    updateEntriesOrder({ variables: { input: { data: entries } } });
  }

  function handleEdit(entriesId: string) {
    history.push(`/entries/${entriesId}`)
  }

  async function handleDeleteEntry() {
    try {
      await deleteEntry({ variables: { where: { id: Number(deletableItemId) } } });
    } finally {
      setDeletableItemId(undefined);
    }
  }

  async function handleExportAsCSV() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'csv' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  async function handleExportAsXLS() {
    try {
      const { data, fileName } = await buildExportablePayload();
      exportFromJSON({ data, fileName, exportType: 'xls' });
    } catch {
      toast('Sorry, something bad happened. Please, try again!');
    }
  }

  const listOptions: SelectFilterOption[] = !listsData?.entryLists ? [] : listsData.entryLists.map(list => ({ value: list.id, label: list.title }));
  listOptions.unshift({ value: '', label: 'All' });

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the entries. Please, try to refresh this page."
          containerClassName="shadow"
        />
      )}

      <DestructiveAlertModal
        isOpen={!!deletableItemId}
        title="Delete Entry"
        message={`Are you sure you want to delete this entry?`}
        destructiveLabel="Delete"
        disabled={loading || removing}
        onDestructiveClick={handleDeleteEntry}
        onCancelClick={() => setDeletableItemId(undefined)}
      />

      <TableFiltersContainer>
        <TableFiltersForm onSubmit={handleFilter}>
          <SearchFilter id="search" width="64" onChange={handleFilterChange} />
          <SelectFilter id="list_id" label="List" width="auto" options={listOptions} onChange={handleFilterChange} />

          <FilterButton onClick={handleFilter} />
        </TableFiltersForm>

        <TableFiltersActionsContainer loading={loading}>
          <PrintButton />
          <ExportDropdownButton onClickDownloadCSV={handleExportAsCSV} onClickDownloadExcel={handleExportAsXLS} />
        </TableFiltersActionsContainer>
      </TableFiltersContainer>

      <DroppableContainer>
        {items.length > 0 && hasNoListFilters && (
          <AlertInfo
            description="You can reorder entries when filtering them by a list."
            containerClassName="m-4 mb-0"
          />
        )}

        <DroppableArea items={items} onOrderChanged={(items) => handleOrderChanged(items as Entries)}>
          {items.map((item, index) => (
            <DraggableItem 
              key={item.id} 
              id={String(item.id)} 
              index={index} 
              title={item.title} 
              description={item.list.title}
              isDragDisabled={hasNoListFilters || loading}
              onEdit={handleEdit} 
              onDelete={setDeletableItemId} 
            />
          ))}
        </DroppableArea>

        <EmptyPanel empty={hasNoResults} />
      </DroppableContainer>
    </>
  );
}

gql`
  mutation updateEntryOrder($input: UpdateEntryOrderInput!) {
    updateEntryOrder(input: $input) {
      success
    }
  }

  mutation deleteOneEntry($where: EntryWhereUniqueInput!) {
    deleteOneEntry(where: $where) {
      id
      title
    }
  }

  query entryListsOnList {
    entryLists {
      id
      title
    }
  }

  query entries($where: EntryWhereInput, $orderBy: EntryOrderByInput) {
    entries(where: $where, orderBy: $orderBy) {
      id
      title
      list {
        id
        title
      }
    }
  }
`;

export default EntriesTable;
