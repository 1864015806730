import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntryListsOnUpdateQueryVariables = {};


export type EntryListsOnUpdateQuery = { entryLists: Array<Pick<Types.EntryList, 'id' | 'title'>> };

export type EntryQueryVariables = {
  where: Types.EntryWhereUniqueInput;
};


export type EntryQuery = { entry?: Types.Maybe<ReturnEntryFragment> };

export type UpdateOneEntryMutationVariables = {
  data: Types.EntryUpdateInput;
  where: Types.EntryWhereUniqueInput;
};


export type UpdateOneEntryMutation = { updateOneEntry?: Types.Maybe<ReturnEntryFragment> };

export type ReturnEntryFragment = (
  Pick<Types.Entry, 'id' | 'title'>
  & { list: Pick<Types.EntryList, 'id' | 'title'> }
);

export const ReturnEntryFragmentDoc = gql`
    fragment ReturnEntry on Entry {
  id
  title
  list {
    id
    title
  }
}
    `;
export const EntryListsOnUpdateDocument = gql`
    query entryListsOnUpdate {
  entryLists {
    id
    title
  }
}
    `;
export function useEntryListsOnUpdateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryListsOnUpdateQuery, EntryListsOnUpdateQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryListsOnUpdateQuery, EntryListsOnUpdateQueryVariables>(EntryListsOnUpdateDocument, baseOptions);
      }
export function useEntryListsOnUpdateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryListsOnUpdateQuery, EntryListsOnUpdateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryListsOnUpdateQuery, EntryListsOnUpdateQueryVariables>(EntryListsOnUpdateDocument, baseOptions);
        }
export type EntryListsOnUpdateQueryHookResult = ReturnType<typeof useEntryListsOnUpdateQuery>;
export type EntryListsOnUpdateLazyQueryHookResult = ReturnType<typeof useEntryListsOnUpdateLazyQuery>;
export type EntryListsOnUpdateQueryResult = ApolloReactCommon.QueryResult<EntryListsOnUpdateQuery, EntryListsOnUpdateQueryVariables>;
export const EntryDocument = gql`
    query entry($where: EntryWhereUniqueInput!) {
  entry(where: $where) {
    ...ReturnEntry
  }
}
    ${ReturnEntryFragmentDoc}`;
export function useEntryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryQuery, EntryQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryQuery, EntryQueryVariables>(EntryDocument, baseOptions);
      }
export function useEntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryQuery, EntryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryQuery, EntryQueryVariables>(EntryDocument, baseOptions);
        }
export type EntryQueryHookResult = ReturnType<typeof useEntryQuery>;
export type EntryLazyQueryHookResult = ReturnType<typeof useEntryLazyQuery>;
export type EntryQueryResult = ApolloReactCommon.QueryResult<EntryQuery, EntryQueryVariables>;
export const UpdateOneEntryDocument = gql`
    mutation updateOneEntry($data: EntryUpdateInput!, $where: EntryWhereUniqueInput!) {
  updateOneEntry(data: $data, where: $where) {
    ...ReturnEntry
  }
}
    ${ReturnEntryFragmentDoc}`;
export type UpdateOneEntryMutationFn = ApolloReactCommon.MutationFunction<UpdateOneEntryMutation, UpdateOneEntryMutationVariables>;
export function useUpdateOneEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneEntryMutation, UpdateOneEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneEntryMutation, UpdateOneEntryMutationVariables>(UpdateOneEntryDocument, baseOptions);
      }
export type UpdateOneEntryMutationHookResult = ReturnType<typeof useUpdateOneEntryMutation>;
export type UpdateOneEntryMutationResult = ApolloReactCommon.MutationResult<UpdateOneEntryMutation>;
export type UpdateOneEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneEntryMutation, UpdateOneEntryMutationVariables>;