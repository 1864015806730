import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EntriesScreen from '../screens/entries/list';
import AddEntryScreen from '../screens/entries/new';
import EditEntryScreen from '../screens/entries/edit';

function EntriesRouter() {
  return (
    <Switch>
      <Route exact path="/entries">
        <EntriesScreen />
      </Route>

      <Route exact path="/entries/new">
        <AddEntryScreen />
      </Route>

      <Route exact path="/entries/:entryId">
        <EditEntryScreen />
      </Route>
    </Switch>
  );
}

export default EntriesRouter;
