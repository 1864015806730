import React from 'react';

type Props = {
  name: string;
  value?: string | string[] | number | null;
  label: string;
  error?: string | null;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'value'>;

function TextInput(props: Props) {
  const { value, label, onChange, error, name, ...inputProps } = props;

  return (
    <div className="flex flex-1 flex-col">
      <label className="font-medium text-sm text-gray-600 mb-2" htmlFor={name}>
        {label}
      </label>

      <div className="flex flex-1 flex-col">
        <input
          className="field-input"
          id={name}
          value={value || ''}
          onChange={(event) => {
            if (!!onChange) {
              event.persist();
              onChange(event);
            }
          }}
          {...inputProps}
        />
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>
    </div>
  );
}
export default TextInput;
