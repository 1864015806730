import { useEffect } from 'react';

const useClickAway = (ref: any, callback: () => void) => {
  useEffect(() => {
    document.addEventListener('click', handleAction);

    return () => {
      document.removeEventListener('click', handleAction);
    };
  });

  function handleAction(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }
};

export default useClickAway;
