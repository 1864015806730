import gql from 'graphql-tag';
import React from 'react';
import { toast } from 'react-toastify';

import AlertDanger from '../../../components/system/alerts/AlertDanger';
import EntryListForm from '../../../components/system/forms/EntryListForm';
import { useEntryListQuery, useUpdateOneEntryListMutation } from './EditEntryListForm.operations';

type Props = {
  entryListId: string;
};

function EditEntryListForm(props: Props) {
  const { data, loading, error } = useEntryListQuery({
    variables: { where: { id: parseInt(props.entryListId) } },
  });

  const [editEntryList] = useUpdateOneEntryListMutation({ refetchQueries: ['entryLists'], awaitRefetchQueries: true });

  const initialValues = {
    title: data?.entryList?.title || '',
  };

  async function handleSubmit(values: typeof initialValues) {
    try {
      await editEntryList({
        variables: {
          data: { title: values.title },
          where: { id: Number(props.entryListId) }
        },
      });

      toast.success(`${values.title} has been updated!`);
    } catch {
      toast.error('Oops, something went wrong.');
    }
  }

  return (
    <>
      {error && (
        <AlertDanger
          description="An error occurred while loading the list. Please, try to refresh this page."
          containerClassName="mb-8"
        />
      )}

      <EntryListForm
        initialValues={initialValues}
        isDisabled={loading || !!error}
        onSubmit={handleSubmit}
      />
    </>
  );
}

gql`
  query entryList($where: EntryListWhereUniqueInput!) {
    entryList(where: $where) {
      ...ReturnEntryList
    }
  }

  mutation updateOneEntryList($data: EntryListUpdateInput!, $where: EntryListWhereUniqueInput!) {
    updateOneEntryList(data: $data, where: $where) {
      ...ReturnEntryList
    }
  }

  fragment ReturnEntryList on EntryList {
    id
    title
  }
`;

export default EditEntryListForm;
