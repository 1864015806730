import React from 'react';
import { MdError as ErrorIcon } from 'react-icons/md';

type Props = {
  title?: string;
  description: string;
  containerClassName?: string;
};

function AlertDanger({ title, description, containerClassName }: Props) {
  return (
    <div className={`bg-red-100 p-4 flex flex-row rounded-lg ${containerClassName}`}>
      <ErrorIcon size="24" className="text-red-600 mr-2" />

      <div>
        {title && <p className="text-red-800 text-sm font-medium mb-1">{title}</p>}
        <p className="text-red-800 text-sm font-light">{description}</p>
      </div>
    </div>
  );
}

export default AlertDanger;
