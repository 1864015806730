import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EntriesSocietyScreen from '../screens/entries-society/list';
import AddEntrySocietyScreen from '../screens/entries-society/new';
import EditEntrySocietyScreen from '../screens/entries-society/edit';

function EntriesSocietyRouter() {
  return (
    <Switch>
      <Route exact path="/entries-society">
        <EntriesSocietyScreen />
      </Route>

      <Route exact path="/entries-society/new">
        <AddEntrySocietyScreen />
      </Route>

      <Route exact path="/entries-society/:entryId">
        <EditEntrySocietyScreen />
      </Route>
    </Switch>
  );
}

export default EntriesSocietyRouter;
