import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type UserQueryVariables = {
  where: Types.UserWhereUniqueInput;
};


export type UserQuery = { user?: Types.Maybe<ReturnUserFragment> };

export type EditUserMutationVariables = {
  input: Types.EditUserInput;
};


export type EditUserMutation = { editUser: { user: ReturnUserFragment } };

export type ReturnUserFragment = (
  Pick<Types.User, 'id' | 'first_name' | 'last_name' | 'username' | 'is_active'>
  & { role?: Types.Maybe<Pick<Types.Role, 'name'>> }
);

export type RolesQueryVariables = {};


export type RolesQuery = { roles: Array<Pick<Types.Role, 'name'>> };

export const ReturnUserFragmentDoc = gql`
    fragment ReturnUser on User {
  id
  first_name
  last_name
  username
  role {
    name
  }
  is_active
}
    `;
export const UserDocument = gql`
    query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ...ReturnUser
  }
}
    ${ReturnUserFragmentDoc}`;
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const EditUserDocument = gql`
    mutation editUser($input: EditUserInput!) {
  editUser(input: $input) {
    user {
      ...ReturnUser
    }
  }
}
    ${ReturnUserFragmentDoc}`;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    name
  }
}
    `;
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;