import React from 'react';
import { NavLink } from 'react-router-dom';

import PageHeader from '../../../components/system/page-header';
import SEO from '../../../components/system/seo';
import MenuPage from '../../../layout/MenuPage';
import UsersTable from './UsersTable';

function UsersScreen() {
  return (
    <>
      <SEO title="Users" />

      <MenuPage>
        <div className="flex flex-1 flex-col p-4 sm:p-8">
          <PageHeader title="Users">
            <NavLink to="/users/new" className="btn btn-primary ml-auto">
              Add User
            </NavLink>
          </PageHeader>

          <UsersTable />
        </div>
      </MenuPage>
    </>
  );
}

export default UsersScreen;
