import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

function TableFiltersContainer({ children, ...props }: React.PropsWithChildren<Props>) {
  return (
    <div className="flex flex-row justify-between items-end my-3 py-4 print:hidden" {...props}>
      {children}
    </div>
  )
}

export default TableFiltersContainer;
