import React from 'react';
import { MdWarning as WarningIcon } from 'react-icons/md';

type Props = {
  title?: string;
  description: string;
  containerClassName?: string;
};

function AlertWarning({ title, description, containerClassName }: Props) {
  return (
    <div className={`bg-orange-100 p-4 flex flex-row rounded-lg ${containerClassName}`}>
      <WarningIcon size="24" className="text-orange-600 mr-2" />

      <div>
        {title && <p className="text-orange-800 text-sm font-medium mb-1">{title}</p>}
        <p className="text-orange-800 text-sm font-light">{description}</p>
      </div>
    </div>
  );
}

export default AlertWarning;
