import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SEO from '../../../components/system/seo';
import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import EditEntryListForm from './EditEntryListForm';

function EditEntryListScreen() {
  const { entryListId } = useParams<{ entryListId: string }>();
  const history = useHistory();

  if (!entryListId) {
    history.replace('/lists');

    return null;
  }

  const title = 'Edit List';
  const breadcrumbs = [
    { to: '/lists/', label: 'Lists' },
    { to: `/lists/${entryListId}`, label: 'Edit' },
  ];

  return (
    <>
      <SEO title="Edit List" />

      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <EditEntryListForm entryListId={entryListId} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default EditEntryListScreen;
