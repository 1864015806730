import * as Types from '../../../types/graphql';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type EntrySocietyListsOnCreateQueryVariables = {};


export type EntrySocietyListsOnCreateQuery = { entrySocietyLists: Array<Pick<Types.EntrySocietyList, 'id' | 'title'>> };

export type CreateOneEntrySocietyMutationVariables = {
  input: Types.CreateEntrySocietyInput;
};


export type CreateOneEntrySocietyMutation = { createOneEntrySociety: { entrySociety: Pick<Types.EntrySociety, 'id' | 'title'> } };


export const EntrySocietyListsOnCreateDocument = gql`
    query entrySocietyListsOnCreate {
  entrySocietyLists {
    id
    title
  }
}
    `;
export function useEntrySocietyListsOnCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntrySocietyListsOnCreateQuery, EntrySocietyListsOnCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<EntrySocietyListsOnCreateQuery, EntrySocietyListsOnCreateQueryVariables>(EntrySocietyListsOnCreateDocument, baseOptions);
      }
export function useEntrySocietyListsOnCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntrySocietyListsOnCreateQuery, EntrySocietyListsOnCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntrySocietyListsOnCreateQuery, EntrySocietyListsOnCreateQueryVariables>(EntrySocietyListsOnCreateDocument, baseOptions);
        }
export type EntrySocietyListsOnCreateQueryHookResult = ReturnType<typeof useEntrySocietyListsOnCreateQuery>;
export type EntrySocietyListsOnCreateLazyQueryHookResult = ReturnType<typeof useEntrySocietyListsOnCreateLazyQuery>;
export type EntrySocietyListsOnCreateQueryResult = ApolloReactCommon.QueryResult<EntrySocietyListsOnCreateQuery, EntrySocietyListsOnCreateQueryVariables>;
export const CreateOneEntrySocietyDocument = gql`
    mutation createOneEntrySociety($input: CreateEntrySocietyInput!) {
  createOneEntrySociety(input: $input) {
    entrySociety {
      id
      title
    }
  }
}
    `;
export type CreateOneEntrySocietyMutationFn = ApolloReactCommon.MutationFunction<CreateOneEntrySocietyMutation, CreateOneEntrySocietyMutationVariables>;
export function useCreateOneEntrySocietyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneEntrySocietyMutation, CreateOneEntrySocietyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneEntrySocietyMutation, CreateOneEntrySocietyMutationVariables>(CreateOneEntrySocietyDocument, baseOptions);
      }
export type CreateOneEntrySocietyMutationHookResult = ReturnType<typeof useCreateOneEntrySocietyMutation>;
export type CreateOneEntrySocietyMutationResult = ApolloReactCommon.MutationResult<CreateOneEntrySocietyMutation>;
export type CreateOneEntrySocietyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneEntrySocietyMutation, CreateOneEntrySocietyMutationVariables>;