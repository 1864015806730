import React from 'react';
import { NavLink } from 'react-router-dom';

import PageHeader from '../../../components/system/page-header';
import SEO from '../../../components/system/seo';
import MenuPage from '../../../layout/MenuPage';
import EntryListsTable from './EntryListsTable';

function EntryListsScreen() {
  return (
    <>
      <SEO title="Lists" />

      <MenuPage>
        <div className="flex flex-1 flex-col p-4 sm:p-8">
          <PageHeader title="Lists">
            <NavLink to="/lists/new" className="btn btn-primary ml-auto">
              Add List
            </NavLink>
          </PageHeader>

          <EntryListsTable />
        </div>
      </MenuPage>
    </>
  );
}

export default EntryListsScreen;
