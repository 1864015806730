import React from 'react';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

import DropdownButton from '../DropdownButton';

type Props = {
  className?: string;
  onClickDownloadExcel?: () => void;
  onClickDownloadCSV?: () => void;
};

function ExportDropdownButton(props: Props) {
  const optionStyle =
    'flex items-center text-left w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900';

  return (
    <DropdownButton
      triggerTitle="Export"
      triggerClassname="rounded-md border border-gray-400 px-4 py-2 text-sm leading-5 font-medium text-gray-600 hover:text-gray-700"
      className={props.className}
    >
      <button onClick={props.onClickDownloadExcel} className={optionStyle}>
        <FaFileExcel className="mr-1" />
        Download as Excel
      </button>

      <button onClick={props.onClickDownloadCSV} className={optionStyle}>
        <FaFileCsv className="mr-1" />
        Download as CSV
      </button>
    </DropdownButton>
  );
}

export default ExportDropdownButton;
