import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import BasicTitlePage from '../../../layout/BasicTitlePage';
import MenuPage from '../../../layout/MenuPage';
import AddEntryForm from './AddEntryForm';

function AddEntryScreen() {
  const history = useHistory();

  const title = 'Add Entry';
  const breadcrumbs = [
    { to: '/entries/', label: 'Entries' },
    { to: '/entries/new', label: 'Add' },
  ];

  return (
    <>
      <Helmet>
        <title>{title} - HDI Admin System</title>
      </Helmet>
      <MenuPage>
        <BasicTitlePage title={title} breadcrumbs={breadcrumbs}>
          <AddEntryForm afterSubmit={() => history.push('/entries')} />
        </BasicTitlePage>
      </MenuPage>
    </>
  );
}

export default AddEntryScreen;
