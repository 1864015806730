import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';

export function getMutationErrorAttribute(error: ApolloError | undefined, attribute: string) {
  return error?.graphQLErrors?.[0].extensions?.[attribute];
}

export function getMutationFieldErrors(errors: readonly GraphQLError[]) {
  let fieldErrors: Record<string, string> = {};

  errors.forEach((error) => {
    const field: string | undefined = error.extensions?.field;

    if (!!field) {
      fieldErrors = { ...fieldErrors, [field]: error.message };
    }
  });

  return fieldErrors;
}
