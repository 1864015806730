import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditUserScreen from '../screens/users/edit';
import UsersScreen from '../screens/users/list';
import AddUserScreen from '../screens/users/new';

function UsersRouter() {
  return (
    <Switch>
      <Route exact path="/users">
        <UsersScreen />
      </Route>

      <Route exact path="/users/new">
        <AddUserScreen />
      </Route>

      <Route exact path="/users/:userId">
        <EditUserScreen />
      </Route>
    </Switch>
  );
}

export default UsersRouter;
